import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import { setCookie, getCookie, checkCookie, test } from '../helpers/CookieHelper';

import Button from './Button';

function Cookies() {
    const [hidden, setHidden] = useState(false);
    const [hasClass, setHasClass] = useState(false);
    const [showCookieNotice, setShowCookieNotice] = useState(true);
    const [cookieName, setCookieName] = useState('mm-cookie-banner');
    const [cookieExpiry, setCookieExpiry] = useState(14);

    const handleHover = () => {
        if (hasClass) {
            setHasClass(false);
        } else {
            setHasClass(true);
            if (500) {
            setTimeout(() => {
                setHasClass(false);
            }, 500);
            }
        }
    };

    useEffect(() => {
        const cookie_banner = getCookie(cookieName);
        if ( !cookie_banner ){
            setShowCookieNotice(true);
            setHidden(false);
        } else {
            setShowCookieNotice(false);
            setHidden(true); 
        }

    }, []);
    
    const handleAcceptCookieNotice = ( action: string ) => {
        // ------------
        // 1. Close pop up
        const current_date = new Date();
        const future_date = DateTime.fromISO( current_date.toISOString() ).plus({ days: 14 });
        const future_date_string = future_date.toJSDate();


        if ( action === 'i-understand' ) {
            // Set the cookie.
            setCookie(cookieName, 'true', cookieExpiry);
            setShowCookieNotice(false);
            setHidden(true);
        } else {
            // Hide pop up but do not set cookie.
            setShowCookieNotice(false);
            setHidden(true);
        }
    };
  return (
    <div>
        <div className={hidden ? "cookie-notice hide" : "cookie-notice"}>
            <p>This website uses cookies to ensure the best experience on our website. By proceeding you accept the terms of the Privacy Policy. <Link onClick={() => setHidden(true)} to={'/legal'}>Read more.</Link></p>
            <div className='ml-auto' onClick={() => { handleAcceptCookieNotice('i-understand'); }}><Button type='button' link='#' linkText='I understand' className="btn btn-primary" /></div>
            <button className={`close ${hasClass ? '' : ""}`} data-animation='xMarks' data-remove={500} onMouseEnter={handleHover} onMouseLeave={() => setHasClass(false)}  onClick={() => { handleAcceptCookieNotice('i-understand'); }}>
              </button>
        </div>
    </div>
  )
}

export default Cookies