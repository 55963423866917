import React, { useEffect, useState } from 'react'
import HeadingTwo from "./HeadingTwo";

interface headingProps {
    title: string
}

function HeadingHardcoded({title}: headingProps) {
    const [inView, setInView] = useState(false);
    const headingRef = React.useRef<HTMLImageElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (headingRef.current) {
          observer.observe(headingRef.current);
        }
        return () => {
          observer.disconnect();
        };
      }, [headingRef]);
  return (
        <div>
            <div ref={headingRef}
            className={`reveal-heading ${inView ? 'slide-up' : ''}`}>
              <HeadingTwo title={title} />
            </div>
        </div>
  )
}

export default HeadingHardcoded