import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useInView } from 'react-intersection-observer';

//Animations
import downloadApp from '../assets/lottie/melon-mobile-download-app-animation.json';
import buildYourOwnPlan from '../assets/lottie/melon-mobile-build-your-own-plan-animation.json';
import activateYourSim from '../assets/lottie/melon-mobile-activate-your-sim-animation.json';

import HeadingTwo from './HeadingTwo';
import Button from './Button';

interface stepsProps {
    title: string,
    image1: string,
    image2: string,
    image3: string,
    copy1: string,
    copy2: string,
    copy3: string,
    link: string,
    linkText: string,
    introSubtitle: string,
    linkType?: string
}

function MelonSteps({...props}: stepsProps) {
    const [inView, setInView] = useState(false);
    const lottieRef = React.useRef<HTMLImageElement>(null);
    const [ref, inView2] = useInView({
        triggerOnce: true,
        rootMargin: '200px'
      });

      useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (lottieRef.current) {
          observer.observe(lottieRef.current);
        }
        return () => {
          observer.disconnect();
        };
    }, [lottieRef]);

    //Download app
    const downloadAppOptions = {
        loop: true,
        autoplay: true, 
        animationData: downloadApp,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    //Build your own plan
    const buildYourOwnPlanOptions = {
        loop: true,
        autoplay: true, 
        animationData: buildYourOwnPlan,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    //Activate Your sim
    const activateYourSimOptions = {
        loop: true,
        autoplay: true, 
        animationData: activateYourSim,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };
  return (
    <div className='melon-steps'>
        <div className="container">
            <div className="row py-0 justify-content-center">
                <div className="col-12 col-md-10 col-lg-8 text-center">
                    {props.title && <HeadingTwo title={props.title} />}
                    {props.introSubtitle && <div className='subtitle' dangerouslySetInnerHTML={{ __html: props.introSubtitle }} />}
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-12 col-md-4 col-lg-3">
                    <div ref={lottieRef}>
                        <div className='d-block lottie-image' ref={ref}>
                            <Lottie options={downloadAppOptions} height={204} width={280} isStopped={!inView} />
                        </div>
                    </div>
                    {props.copy1 && <div dangerouslySetInnerHTML={{ __html: props.copy1 }} />}
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                    <div ref={lottieRef}>
                        <div className='d-block lottie-image' ref={ref}>
                            <Lottie options={buildYourOwnPlanOptions} height={204} width={280} isStopped={!inView} />
                        </div>
                    </div>
                    {props.copy2 && <div dangerouslySetInnerHTML={{ __html: props.copy2 }} />}
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                    <div ref={lottieRef}>
                        <div className='d-block lottie-image' ref={ref}>
                            <Lottie options={activateYourSimOptions} height={204} width={280} isStopped={!inView} />
                        </div>
                    </div>
                    {props.copy3 && <div dangerouslySetInnerHTML={{ __html: props.copy3 }} /> }
                </div>
            </div>
            <div className="link text-center">
              {props.link === '' ? null : <Button type='button' className='btn btn-primary' linkText={props.linkText} link={props.link} linkType={props.linkType ? props.linkType : ''} />}
            </div>
        </div>
    </div>
  )
}

export default MelonSteps