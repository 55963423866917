import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from './Button';

interface bannerProps {
    title: string,
    subtitle: string,
    link: string,
    linkText: string,
    link2: string,
    linkText2: string,
    image: string,
}

function ErrorBanner({...props}: bannerProps) {
    const [inView, setInView] = useState(false);
    const headingRef = React.useRef<HTMLImageElement>(null);
        
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (headingRef.current) {
          observer.observe(headingRef.current);
        }
        return () => {
          observer.disconnect();
        };
    }, [headingRef]);
  return (
    <div className='error-banner'>
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-6">
                    {props.title === '' ? null : <div ref={headingRef}
                    className={`reveal-heading ${inView ? 'slide-up' : ''}`} dangerouslySetInnerHTML={{ __html: props.title }} />}
                    {props.subtitle === '' ? null : <p>{props.subtitle}</p>}
                    <div className='links'>
                        <Button 
                            className='btn btn-white'
                            type='button'
                            link={props.link}
                            linkText={props.linkText}
                        />
                        <Link to={props.link2}>{props.linkText2}</Link> 
                    </div>
                </div>
            </div>
        </div>
        {props.image === '' ? null : <div className='image' dangerouslySetInnerHTML={{ __html: props.image }} />}
    </div>
  )
}

export default ErrorBanner