import { createRoutesFromElements, createBrowserRouter, createMemoryRouter, Route, Routes } from 'react-router-dom';
import Header from './components/Header';


// Pages
import Home from "./pages/Home";
import WhoWeAre from "./pages/WhoWeAre";
import Error404 from "./pages/Error404";
import fetchData from "./functions/fetchData";
import WhyUseMelonMobile from "./pages/WhyUseMelonMobile";
import HowItWorks from "./pages/HowItWorks";
import DownloadApp from "./pages/DownloadApp";
import Packages from "./pages/Packages";
import Rewards from "./pages/Rewards";
import Deals from "./pages/Deals";
import Blog from "./pages/Blog";
import BlogSingle from "./pages/BlogSingle";
import GetASimPromo from "./pages/GetASimPromo";
import HelpCenter from "./pages/HelpCenter";
import Contact from "./pages/Contact";
import ThankYou from "./pages/ThankYou";
import Search from "./pages/Search";
import Legal from "./pages/Legal";
import PackageSingle from "./pages/PackageSingle";
import TermsOfUse from './pages/TermsOfUse';
import SelfHelp from './pages/SelfHelp';
import TermsAndConditions from './pages/TermsAndConditions';
import DataDeletion from './pages/DataDeletion';
import React, { Suspense } from 'react';


// export default [
//     <Route path="/" element={<Header />} />,
//     <Route
//         element={<Home/>}
//         path={'/'}
//         loader = {async ({})=> await fetchData('page',5)}/>,
//     <Route
//     element={<WhoWeAre/>}
//     path={'/who-we-are'}
//     loader = {async ({})=> fetchData('page',59)}/>,

//     <Route
//         element={<WhyUseMelonMobile/>}
//         path={'/why-use-melon-mobile'}
//         loader = {async ({})=> fetchData('page',68)}/>,

//     <Route
//         element={<HowItWorks/>}
//         path={'/how-it-works'}
//         loader = {async ({})=> fetchData('page',70)}/>,

//     <Route
//         element={<DownloadApp/>}
//         path={'/download-app'}
//         loader = {async ({})=> fetchData('page',72)}/>,

//     <Route
//         element={<Packages/>}
//         path={'/packages'}
//         loader = {async ({})=> fetchData('page',74)}/>,

//     <Route
//         element={<PackageSingle/>}
//         path={'/packages/:slug'}
//         loader = {async ({ params })=> fetchData('packages', params.slug)}/>,

//     <Route
//         element={<Rewards/>}
//         path={'/rewards'}
//         loader = {async ({})=> fetchData('page',76)}/>,

//     <Route
//         element={<Deals/>}
//         path={'/deals'}
//         loader = {async ({})=> fetchData('page',78)}/>,

//     <Route
//         element={<Blog/>}
//         path={'/blog'}
//         loader = {async ({})=> fetchData('page',80)}/>,

//     <Route
//         element={<BlogSingle/>}
//         path={'/blog/:slug'}
//         loader = {async ({ params })=> fetchData('posts',params.slug)}/>,

//     <Route
//         element={<GetASimPromo/>}
//         path={'/get-a-sim-promo'}
//         loader = {async ({})=> fetchData('page',82)}/>,

//     <Route
//         element={<HelpCenter/>}
//         path={'/help-center'}
//         loader = {async ({})=> fetchData('page',84)}/>,

//     <Route
//         element={<Contact/>}
//         path={'/contact'}
//         loader = {async ({})=> fetchData('page',86)}/>,

//     <Route
//         element={<ThankYou/>}
//         path={'/thank-you'}
//         loader = {async ({})=> fetchData('page',88)}/>,

//     <Route
//         element={<Error404/>}
//         path={'*'}
//         loader = {async ({})=> fetchData('page',90)}/>,
//     <Route
//     element={<Error404/>}
//     path={'/packages/*'}
//     loader = {async ({})=> fetchData('page',90)}/>,
//     <Route
//     element={<Error404/>}
//     path={'/blog/*'}
//     loader = {async ({})=> fetchData('page',90)}/>,

//     <Route
//         element={<Search/>}
//         path={'/search'}
//         loader = {async ({})=> fetchData('page',92)}/>,

//     <Route
//         element={<Legal/>}
//         path={'/legal'}
//         loader = {async ({})=> fetchData('page',94)}/>,

//     <Route
//         element={<TermsOfUse/>}
//         path={'/terms-of-use'}
//         loader = {async ({})=> fetchData('page',359)}/>,

//     <Route
//         element={<SelfHelp/>}
//         path={'/self-help'}
//         loader = {async ({})=> fetchData('page',523)}/>,

//     <Route
//         element={<TermsAndConditions/>}
//         path={'/terms-and-conditions'}
//         loader = {async ({})=> fetchData('page',696)}/>,

//     <Route
//         element={<DataDeletion/>}
//         path={'/data-deletion-policy'}
//         loader = {async ({})=> fetchData('page',697)}/>
// ];

export const router = createBrowserRouter(
  createRoutesFromElements(
      <Route path="/" element={<Header />} >
        {/*Home*/}
        <Route
                element={<Home/>}
                path={'/'}
                loader = {async ({})=> await fetchData('page',5)}/>

        {/*Who We Are*/}
        <Route
            element={<WhoWeAre/>}
            path={'/who-we-are'}
            loader = {async ({})=> fetchData('page',59)}/>

        {/*Why Use Melon Mobile*/}
        <Route
            element={<WhyUseMelonMobile/>}
            path={'/why-use-melon-mobile'}
            loader = {async ({})=> fetchData('page',68)}/>

        {/*How it Works*/}
        <Route
            element={<HowItWorks/>}
            path={'/how-it-works'}
            loader = {async ({})=> fetchData('page',70)}/>

        {/*Download App*/}
        <Route
            element={<DownloadApp/>}
            path={'/download-app'}
            loader = {async ({})=> fetchData('page',72)}/>

        {/*Packages*/}
        <Route
            element={<Packages/>}
            path={'/packages'}
            loader = {async ({})=> fetchData('page',74)}/>

        {/*Individual Package*/}
        <Route
            element={<PackageSingle/>}
            path={'/packages/:slug'}
            loader = {async ({ params })=> fetchData('packages', params.slug)}/>

        {/*Rewards*/}
        <Route
            element={<Rewards/>}
            path={'/rewards'}
            loader = {async ({})=> fetchData('page',76)}/>

        {/*Deals*/}
        <Route
            element={<Deals/>}
            path={'/deals'}
            loader = {async ({})=> fetchData('page',78)}/>

        {/*Blog*/}
        <Route
            element={<Blog/>}
            path={'/blog'}
            loader = {async ({})=> fetchData('page',80)}/>

        {/*Individual Blog*/}
        <Route
            element={<BlogSingle/>}
            path={'/blog/:slug'}
            loader = {async ({ params })=> fetchData('posts',params.slug)}/>

        {/*Get a Sim Promo*/}
        <Route
            element={<GetASimPromo/>}
            path={'/get-a-sim-promo'}
            loader = {async ({})=> fetchData('page',82)}/>

        {/*Help Center*/}
        <Route
            element={<HelpCenter/>}
            path={'/help-center'}
            loader = {async ({})=> fetchData('page',84)}/>

        {/*Contact*/}
        <Route
            element={<Contact/>}
            path={'/contact'}
            loader = {async ({})=> fetchData('page',86)}/>

        {/*Thank you*/}
        <Route
            element={<ThankYou/>}
            path={'/thank-you'}
            loader = {async ({})=> fetchData('page',88)}/>

        {/*404*/}
        <Route
            element={<Error404/>}
            path={'*'}
            loader = {async ({})=> fetchData('page',90)}/>
        <Route
        element={<Error404/>}
        path={'/packages/*'}
        loader = {async ({})=> fetchData('page',90)}/>
        <Route
        element={<Error404/>}
        path={'/blog/*'}
        loader = {async ({})=> fetchData('page',90)}/>

        <Route
            element={<Search/>}
            path={'/search'}
            loader = {async ({})=> fetchData('page',92)}/>

        {/*Legal*/}
        <Route
            element={<Legal/>}
            path={'/legal'}
            loader = {async ({})=> fetchData('page',94)}/>

        {/* Terms of use */}
        <Route
            element={<TermsOfUse/>}
            path={'/terms-of-use'}
            loader = {async ({})=> fetchData('page',359)}/>

        {/* Self-Help */}
        <Route
            element={<SelfHelp/>}
            path={'/self-help'}
            loader = {async ({})=> fetchData('page',523)}/>

        {/* Terms and Conditions */}
        <Route
            element={<TermsAndConditions/>}
            path={'/terms-and-conditions'}
            loader = {async ({})=> fetchData('page',696)}/>

        {/* Data Deletion*/}
        <Route
            element={<DataDeletion/>}
            path={'/data-deletion-policy'}
            loader = {async ({})=> fetchData('page',697)}/>

      </Route>
  ), {  }
)