import React, { useState, useEffect } from 'react';
import Pagination from '../components/Pagination';
import LatestPostBanner from '../components/LatestPostBanner';
import {Link, useLoaderData} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Navigation from '../components/Navigation';
import {PageData} from "../types/PageData";

interface BlogPost {
    slug: string,
    title: string;
    content: string;
    categories: string[];
    thumbnail: string,
    date: string
}

interface Props {}

const Blog = () => {
    const [posts, setPosts] = useState<BlogPost[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [categories, setCategories] = useState<string[]>([]);
    const [activeCategory, setActiveCategory] = useState<null | string>(null);
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const PageData = useLoaderData() as PageData;
    const yoast:any = PageData.yoast_head_json


    useEffect(() => {
        document.body.style.scrollBehavior = "auto";
        document.body.scrollTop = 0;
        document.documentElement.style.scrollBehavior = "auto";
        document.documentElement.scrollTop = 0;
    }, []);

    useEffect(() => {
        const fetchPosts = async () => {
        try {
            setIsLoading(true);
            const res = await fetch(
            `${process.env.REACT_APP_SECRET_MELON_MOBILE_CONTENT}wp-json/wp/v2/posts?_embed`
            );
            if (!res.ok) {
            throw new Error(res.statusText);
            }
            const data = await res.json();
            setPosts(data.map((post: any) => {
                const date = new Date(post.date);
                const options:any = { day: 'numeric', month: 'long', year: 'numeric' };
                const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
                return {
                    slug: post.slug,
                    title: post.title.rendered,
                    content: post.content.rendered,
                    categories: post._embedded["wp:term"][0].map((term: any) => term.name),
                    thumbnail: post._embedded["wp:featuredmedia"] && post._embedded["wp:featuredmedia"][0].media_details.sizes.full.source_url,
                    date: formattedDate
                }
            })); 
            setIsLoading(false);
        } catch (err: any) {
            setError(err);
            setIsLoading(false);
        }
        };

        const fetchCategories = async () => {
        try {
            const res = await fetch(
            `${process.env.REACT_APP_SECRET_MELON_MOBILE_CONTENT}wp-json/wp/v2/categories`
            );
            const data = await res.json();
            const categories = data.map((category: any) => category.name);
            setCategories(categories);
        } catch (err: any) {
            setError(err);
        }
        };

        fetchPosts();
        fetchCategories();
    }, []);
  
    if (error) {
        return <div>An error occured: {error.message}</div>;
    }
    
    if (isLoading) {
        return <div>Loading...</div>;
    }

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };
    
    // Category filtering
    const handleCategoryChange = (category: string | null) => {
        setActiveCategory(category);
    };
    
    const filteredPosts = !posts ? [] : (
    activeCategory === null
        ? posts
        : posts.filter((post: BlogPost) => post.categories && post.categories.includes(activeCategory))
    );

    // Pagination
    const totalPages = Math.ceil(filteredPosts.length / 12);
    const paginatedPosts = !filteredPosts ? [] : filteredPosts.slice(
    (currentPage - 1) * 12,
    currentPage * 12
    );

    const sortedPosts = posts.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    const latestPost = sortedPosts[0];

  return (
    <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{yoast !== undefined ? yoast.og_title : 'Blog - Melon Mobile'}</title>
            <meta name="description" content={yoast !== undefined ? yoast.description : 'We believe the current way you’re forced to mobile is broken. With big networks offering things that really only benefit themselves. Long-term contracts, outdated plans, complicated experiences, and a one-size-fits-all approach have left most of us feeling rather bitter.'}/>
            <meta property="og:description" content={yoast !== undefined ? yoast.og_description: 'We believe the current way you’re forced to mobile is broken. With big networks offering things that really only benefit themselves. Long-term contracts, outdated plans, complicated experiences, and a one-size-fits-all approach have left most of us feeling rather bitter.'} />
            <meta property="og:locale" content={yoast !== undefined ? yoast.og_locale : 'en_US'} />
            <meta property="og:type" content={yoast !== undefined ? yoast.og_type : 'article'} />
            <meta property="og:title" content={yoast !== undefined ? yoast.og_title : 'Blog - Melon Mobile'} />
            <meta property="og:site_name" content={yoast !== undefined ? yoast.og_site_name : 'Melon Mobile'} />
            <meta property="og:url" content={yoast !== undefined ? yoast.og_url : `${process.env.REACT_APP_SECRET_MELON_MOBILE_CONTENT}blog/`} />
        </Helmet>
        <Navigation />
        <LatestPostBanner post={latestPost} />
        <div className="container">
            <ul className='post-categories'>
                <li key="all" className={activeCategory === null ? 'active' : ''} onClick={() => handleCategoryChange(null)}>All</li>
                {categories.map((category: string) => (
                <li key={category} className={activeCategory === category ? 'active' : ''} onClick={() => handleCategoryChange(category)}>
                    {category}
                </li>
                ))}
            </ul>
        </div>
        <div className="container blog-posts">
            <div className='row'>
                {paginatedPosts.map((post: BlogPost) => (
                    <div key={post.title} className='col-12 col-md-4 col-lg-3 blog-post'>
                        <Link key={post.title} to={`/blog/${post.slug}`}>
                            <img src={post.thumbnail} alt={post.title}/>
                            <div className="content">
                                <div className="post-date">{post.date}</div>
                                <h4>{post.title}</h4>
                            </div>
                        </Link>
                    </div>
                ))}
          </div>
        </div>
      <div>
      { posts.length > 1 && totalPages === 1 ? null : (
        <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            prevButtonVisible={currentPage > 1}
            nextButtonVisible={currentPage < totalPages}
        />
        )}
        </div>
    </div>
  );
};

export default Blog;