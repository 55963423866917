import React from 'react';
import { Link } from 'react-router-dom';
import HeadingHardcoded from './HeadingHardcoded';

interface BannerProps {
    post: any
}

const LatestPostBanner = ({ post }: BannerProps) => {
    if (!post) {
        return <div>Loading...</div>
    }
    return (
        <div className="container">
            <div className="latest-post-banner" style={{ backgroundImage: `url(${post.thumbnail})` }}>
                <div>
                    <p className="latest-post-date">{post.date}</p>
                    <Link to={post.slug}><HeadingHardcoded title={post.title} /></Link>
                </div>
            </div>
        </div>
    )
}

export default LatestPostBanner;