import React, { useState } from 'react';
import open from '../assets/images/icons/arrow-up-s-line.svg';
import closed from '../assets/images/icons/arrow-right-s-line.svg';
interface accordionProps {
    id: string;
    onClick: (childId: string) => void;
    isSelected: boolean;
    question: string;
    answer: string;
}
function Accordion({question, answer, id, onClick, isSelected}: accordionProps) {
    const [isActive, setIsActive] = useState(false);
  return (
    <div className='accordion'>
        <div>
            <div onClick={() => onClick(id)} className={isSelected ? 'question active' : 'question'}>
                <h4>{question}</h4>
                <i className={isSelected ? 'icon icon-arrow-open'  : 'icon icon-arrow-closed' }></i>
            </div>
            <div className={isSelected ? 'answer show' : 'answer hide'}>
                <p dangerouslySetInnerHTML={{__html: answer}}></p>
            </div>
        </div>
    </div>
  )
}

export default Accordion