import React, { useEffect } from 'react';
import {useLoaderData} from "react-router-dom";
import { PageData } from "../types/PageData";
import Banner from '../components/Banner';
import Testimonials from '../components/Testimonials';
import MoreBenefits from '../components/MoreBenefits';
import ContentImage from '../components/ContentImage';
import SupportCta from '../components/SupportCta';

import background from '../assets/images/Melon-Mobile-Banner-Background.jpg';
import { Helmet } from 'react-helmet-async';
import HeadingTwo from '../components/HeadingTwo';
import Navigation from '../components/Navigation';

const WhyUseMelonMobile = () => {
  // Page Data From Router
    const PageData = useLoaderData() as PageData;
    const items = PageData.content.no_short_codes;
    const aiButtonHref = /.*href="([^"]*)".*title="([^"]*)".*/;
    const yoast:any = PageData.yoast_head_json


  useEffect(() => {
        document.body.style.scrollBehavior = "auto";
        document.body.scrollTop = 0;
        document.documentElement.style.scrollBehavior = "auto";
        document.documentElement.scrollTop = 0;
    }, []);

    return (
        <div className='why-use-melon'>
            <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : "Why melon - Melon Mobile"}</title>
            <meta charSet="utf-8" />
            <meta name="description" content={yoast !== undefined ? yoast.description : "We believe the current way you’re forced to mobile is broken. With big networks offering things that really only benefit themselves. Long-term contracts, outdated plans, complicated experiences, and a one-size-fits-all approach have left most of us feeling rather bitter."}/>
            <meta property="og:description" content={yoast !== undefined ? yoast.og_description: "We believe the current way you’re forced to mobile is broken. With big networks offering things that really only benefit themselves. Long-term contracts, outdated plans, complicated experiences, and a one-size-fits-all approach have left most of us feeling rather bitter."} />
            <meta property="og:locale" content={yoast !== undefined ? yoast.og_locale : "en_US"} />
            <meta property="og:type" content={yoast !== undefined ? yoast.og_type : "article"} />
            <meta property="og:title" content={yoast !== undefined ? yoast.og_title : "Why melon - Melon Mobile"} />
            <meta property="og:site_name" content={yoast !== undefined ? yoast.og_site_name : "Melon Mobile"} />
            <meta property="og:image" content={yoast !== undefined ? yoast.og_image[0].url:"http://melonmobilecontent.agletinteractive.com/wp-content/uploads/2023/01/Melon-Mobile-Testimonial-Moji-01.svg"} />
            <meta property="og:url" content={yoast !== undefined ? yoast.og_url : `${process.env.REACT_APP_SECRET_MELON_MOBILE_CONTENT}why-use-melon-mobile/`} />
            </Helmet>
            <Navigation />
            <Banner badge={PageData.title.rendered} title={items[0][0][0]} background={background} />

            <MoreBenefits
                title={items[1][0][0]}
                image1={items[1][1][0]}
                subtitle1={items[1][1][1]}
                copy1={items[1][1][2]}
                image2={items[1][1][3]}
                subtitle2={items[1][1][4]}
                copy2={items[1][1][5]}
                image3={items[1][1][6]}
                subtitle3={items[1][1][7]}
                copy3={items[1][1][8]}
                image4={items[1][1][9]}
                subtitle4={items[1][1][10]}
                copy4={items[1][1][11]}
                image5={items[1][1][12]}
                subtitle5={items[1][1][13]}
                copy5={items[1][1][14]}
                image6={items[1][1][15]}
                subtitle6={items[1][1][16]}
                copy6={items[1][1][17]}
            />

            <div className="container ai-column-text">
                {items[2][0][0] === '' ? null : <HeadingTwo title={items[2][0][0]} />}
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                        {items[2][1][0] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[2][1][0]}} />}
                        {items[2][1][1] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[2][1][1]}} />}
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        {items[2][2][0] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[2][2][0]}} />}
                        {items[2][2][1] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[2][2][1]}} />}
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        {items[2][3][0] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[2][3][0]}} />}
                        {items[2][3][1] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[2][3][1]}} />}
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        {items[2][4][0] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[2][4][0]}} />}
                        {items[2][4][1] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[2][4][1]}} />}
                    </div>
                </div>
            </div>

            <ContentImage
                layout='image left'
                image={items[3][0]}
                title={items[3][1]}
                copy={items[3][2]}
                list=''
                link=''
                linkText=''
                numbers=''
                animation = {true}
                animationName = 'available-in-sa'
            />

            <ContentImage
                layout='image right'
                image={items[4][0]}
                title={items[4][1]}
                copy={items[4][2]}
                list={items[4][3][0]+items[4][3][1]+items[4][3][2]+items[4][3][3]+items[4][3][4]}
                link={items[4][4][0].replace(aiButtonHref, '$1')}
                linkText={items[4][4][0].replace(aiButtonHref, '$2')}
                numbers=''
            />

            <Testimonials
                slideSpeed={5000}
                title={items[5][0][0]}
                copy1={items[5][1][0]}
                icon1={items[5][1][1]}
                name1={items[5][1][2]}
                copy2={items[5][2][0]}
                icon2={items[5][2][1]}
                name2={items[5][2][2]}
                copy3={items[5][3][0]}
                icon3={items[5][3][1]}
                name3={items[5][3][2]}
                copy4={items[5][4][0]}
                icon4={items[5][4][1]}
                name4={items[5][4][2]}
                copy5={items[5][5][0]}
                icon5={items[5][5][1]}
                name5={items[5][5][2]}
            />

            <SupportCta />
        </div>
    );
};

export default WhyUseMelonMobile;