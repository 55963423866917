import React from 'react';

interface Props {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
    prevButtonVisible: boolean;
    nextButtonVisible: boolean;
}

export default function Pagination({
    currentPage,
    totalPages,
    onPageChange,
    prevButtonVisible,
    nextButtonVisible
  }: Props) {
    const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  
    return (
      <div className="pagination">
        {prevButtonVisible && (
          <button className='btn btn-primary' onClick={() => onPageChange(currentPage - 1)}>Previous Page</button>
        )}
        {pages.map((page) => (
          <button className={currentPage === page ? 'page-number active' : 'page-number'} key={page} onClick={() => onPageChange(page)}>{page}</button>
        ))}
        {nextButtonVisible && (
          <button className='btn btn-primary' onClick={() => onPageChange(currentPage + 1)}>Next Page</button>
        )}
      </div>
    );
  }