import React, { useState, useEffect } from 'react';
import {useLoaderData} from "react-router-dom";
import { PageData } from "../types/PageData";
import Banner from '../components/Banner';
import Accordion from '../components/Accordion';
import MelonSteps from '../components/MelonSteps';

import background from '../assets/images/Melon-Mobile-Banner-Background.jpg';
import ContentImage from '../components/ContentImage';
import SupportCta from '../components/SupportCta';
import { Helmet } from 'react-helmet-async';
import HeadingTwo from '../components/HeadingTwo';
import Navigation from '../components/Navigation';

const Rewards = () => {
    const [selectedChild, setSelectedChild] = useState<string | null>(null);

    const handleClick = (childId: string) => {
      setSelectedChild(childId);
    };
    // Page Data From Router
    const PageData = useLoaderData() as PageData;
    const items = PageData.content.no_short_codes;
    const yoast:any = PageData.yoast_head_json
    const aiButtonHref = /.*href="([^"]*)".*title="([^"]*)".*/;

    useEffect(() => {
        document.body.style.scrollBehavior = "auto";
        document.body.scrollTop = 0;
        document.documentElement.style.scrollBehavior = "auto";
        document.documentElement.scrollTop = 0;
    }, []);

  return (
    <div className='rewards'>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : "Rewards - Melon Mobile"}</title>
            <meta charSet="utf-8" />
            <meta name="description" content={yoast !== undefined ? yoast.description : "We believe the current way you’re forced to mobile is broken. With big networks offering things that really only benefit themselves. Long-term contracts, outdated plans, complicated experiences, and a one-size-fits-all approach have left most of us feeling rather bitter."}/>
            <meta property="og:description" content={yoast !== undefined ? yoast.og_description: "We believe the current way you’re forced to mobile is broken. With big networks offering things that really only benefit themselves. Long-term contracts, outdated plans, complicated experiences, and a one-size-fits-all approach have left most of us feeling rather bitter."} />
            <meta property="og:locale" content={yoast !== undefined ? yoast.og_locale : "en_US"} />
            <meta property="og:type" content={yoast !== undefined ? yoast.og_type : "article"} />
            <meta property="og:title" content={yoast !== undefined ? yoast.og_title : "Rewards - Melon Mobile"} />
            <meta property="og:site_name" content={yoast !== undefined ? yoast.og_site_name : "Melon Mobile"} />
            <meta property="og:url" content={yoast !== undefined ? yoast.og_url : `${process.env.REACT_APP_SECRET_MELON_MOBILE_CONTENT}rewards/`} />
        </Helmet>
        <Navigation />
        <Banner 
            badge={PageData.title.rendered} 
            title={items[0][0][0]} 
            background={background} 
        />

        <div className="centered-text container">
            <div className="row">
                <div className="col-12 col-md-10 col-lg-8 mx-auto">
                    {items[1][0][0] === '' ? null : <HeadingTwo title={items[1][0][0]} />}
                </div>
                <div className="col-12 col-md-10 col-lg-10 mx-auto">
                    {items[1][1][0] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[1][1] }} />}
                </div>
            </div>
        </div>

        <ContentImage 
            layout='image left'
            image={items[2][0]}
            title={items[2][1]}
            copy={items[2][2]}
            link=''
            linkText=''
            list=''
            numbers=''
        />

        <ContentImage 
            layout='image right'
            image={items[3][0]}
            title={items[3][1]}
            copy={items[3][2]}
            link=''
            linkText=''
            list=''
            numbers=''
        />

        <div className="bg-light component">
            <ContentImage 
                layout='image left'
                image={items[4][0]}
                title={items[4][1]}
                copy={items[4][2]}
                link=''
                linkText=''
                list=''
                numbers=''
            />

            <ContentImage 
                layout='image right'
                image={items[5][0]}
                title={items[5][1]}
                copy={items[5][2]}
                link=''
                linkText=''
                list=''
                numbers=''
            />
        </div>

        <MelonSteps 
            title={items[6][0][0]} 
            introSubtitle={items[6][1][0]} 
            image1={items[6][2][0]} 
            image2={items[6][4][0]} 
            image3={items[6][6][0]} 
            copy1={items[6][3][0]} 
            copy2={items[6][5][0]} 
            copy3={items[6][7][0]} 
            link={items[6][8][0].replace(aiButtonHref, '$1')} 
            linkText={items[6][8][0].replace(aiButtonHref, '$2')} 
        />

        <div className="container accordion-wrapper">
            {items[7][0][0] === '' ? null : <HeadingTwo title={items[7][0][0]} />}

            <Accordion 
                id="1" onClick={handleClick} isSelected={selectedChild === '1'}
                question={items[7][1][0]}
                answer={items[7][1][1]}
            />
            <Accordion 
                id="2" onClick={handleClick} isSelected={selectedChild === '2'}
                question={items[7][1][2]}
                answer={items[7][1][3]}
            />
            <Accordion
                id="3" onClick={handleClick} isSelected={selectedChild === '3'} 
                question={items[7][1][4]}
                answer={items[7][1][5]}
            />
            <Accordion
                id="4" onClick={handleClick} isSelected={selectedChild === '4'} 
                question={items[7][1][6]}
                answer={items[7][1][7]}
            />
            <Accordion
                id="5" onClick={handleClick} isSelected={selectedChild === '5'} 
                question={items[7][1][8]}
                answer={items[7][1][9]}
            />
        </div>

        <SupportCta />
    </div>
  );
};

export default Rewards;