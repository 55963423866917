import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import background from "../assets/images/Melon-Mobile-Testimonials-Background.svg";
import HeadingTwo from './HeadingTwo';

interface sliderProps {
    slideSpeed: number,
    title: string,
    copy1: string,
    icon1: string,
    name1: string,
    copy2: string,
    icon2: string,
    name2: string,
    copy3: string,
    icon3: string,
    name3: string,
    copy4: string,
    icon4: string,
    name4: string,
    copy5: string,
    icon5: string,
    name5: string,
}

function Testimonials({...props} : sliderProps) {
    const containerRef = useRef<HTMLDivElement>(null);
    const settings:any = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "9%",
        autoplay: true,
        autoplaySpeed: props.slideSpeed,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    }

    let SliderData:any = [
        {
            id: 1,
            name: props.name1,
            copy: props.copy1,
            icon: props.icon1
        },
        {
            id: 2,
            name: props.name2,
            copy: props.copy2,
            icon: props.icon2
        },
        {
            id: 3,
            name: props.name3,
            copy: props.copy3,
            icon: props.icon3
        },
        {
            id: 4,
            name: props.name4,
            copy: props.copy4,
            icon: props.icon4
        },
        {
            id: 5,
            name: props.name5,
            copy: props.copy5,
            icon: props.icon5
        }
    ]

  return (
    <div className='testimonails' ref={containerRef}>
        {props.title === '' ? null : <HeadingTwo title={props.title} />}
        <Slider { ...settings }>
            {SliderData.map((slider:any) => (
                <div key={slider.id} className="slide">
                    <div>
                        {slider.copy === '' ? null : <div className='content' dangerouslySetInnerHTML={{ __html: slider.copy }} />}
                        <div className="author">
                            {slider.icon === '' ? null : <div dangerouslySetInnerHTML={{ __html: slider.icon }} />}
                            {slider.name === '' ? null : <div dangerouslySetInnerHTML={{ __html: slider.name }} />}
                        </div>
                    </div>
                </div>
            ))}
        </Slider>
    </div>
  )
}

export default Testimonials