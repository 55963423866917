import React, { useState } from 'react';
import twitter from '../assets/images/icons/twitter-fill.svg';
import facebook from '../assets/images/icons/facebook-fill.svg';
import urlIcon from '../assets/images/icons/links-line.svg';


//Icons
import { RiTwitterFill } from 'react-icons/ri';
import { RiLinksLine } from 'react-icons/ri';
import { FaFacebook } from 'react-icons/fa';

interface Props {
  url: string;
  title: string;
  twitterHandle?: string;
}

const ShareLinks: React.FC<Props> = ({ url, title, twitterHandle = '' }) => {
    const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    const twitterShareLink = twitterHandle
      ? `https://twitter.com/share?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}&via=${encodeURIComponent(twitterHandle)}`
      : `https://twitter.com/share?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`;

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(url);
    setIsCopied(true);
    console.log(url)
  };

  return (
    <div className='links'>
      <a href={facebookShareLink}  target="_blank" rel="noopener noreferrer">
        <FaFacebook/>
      </a>
      <a href={twitterShareLink} target="_blank" rel="noopener noreferrer">
        <RiTwitterFill/>
      </a>
      <button title='Copy link' onClick={handleCopyClick}>
        <RiLinksLine/>
      </button>
    </div>
  );
};

export default ShareLinks;