import React, { useState, useEffect } from 'react';
import {useLoaderData} from "react-router-dom";
import { PageData } from "../types/PageData";
import Banner from '../components/Banner';
import Accordion from '../components/Accordion';
//import OwnPlan from '../components/OwnPlan';
 
import background from '../assets/images/Melon-Mobile-Banner-Background.jpg';
import { Helmet } from 'react-helmet-async';
import { PageTransition } from '../components/PageTransition';
import HeadingTwo from '../components/HeadingTwo';
import PackagesTiles from '../components/PackagesTiles';
import SupportCta from '../components/SupportCta';
import Button from '../components/Button';
import AllPackages from '../components/AllPackages';
import OwnPlan from '../components/OwnPlan';
import Navigation from '../components/Navigation';


const Packages = () => {
    const [selectedChild, setSelectedChild] = useState<string | null>(null);

      const handleClick = (childId: string) => {
        setSelectedChild(childId);
    };
    // Page Data From Router
    const PageData = useLoaderData() as PageData
    const items = PageData.content.no_short_codes;
    const yoast:any = PageData.yoast_head_json


  useEffect(() => {
        document.body.style.scrollBehavior = "auto";
        document.body.scrollTop = 0;
        document.documentElement.style.scrollBehavior = "auto";
        document.documentElement.scrollTop = 0;
    }, []);
    
  return (
    <div className='packages'>
        <>
            {
                console.log("Yoast: ", yoast)
            }
        </>
        <Helmet>

            <title>{yoast !== undefined ? yoast.og_title : "Melon Plans - Melon Mobile"}</title>
            <meta charSet="utf-8" />
            <meta name="description" content={yoast !== undefined ? yoast.description : "Melon gives you the freedom to go your own way. You can build your own plan or select a plan that works for you."}/>
            <meta property="og:description" content={yoast !== undefined ? yoast.og_description: "Melon gives you the freedom to go your own way. You can build your own plan or select a plan that works for you."} />
            <meta property="og:locale" content={yoast !== undefined ? yoast.og_locale : "en_US"} />
            <meta property="og:type" content={yoast !== undefined ? yoast.og_type : "article"} />
            <meta property="og:title" content={yoast !== undefined ? yoast.og_title : "Melon Plans - Melon Mobile"} />
            <meta property="og:site_name" content={yoast !== undefined ? yoast.og_site_name : "Melon Mobile"} />
            <meta property="og:url" content={yoast !== undefined ? yoast.og_url : `${process.env.REACT_APP_SECRET_MELON_MOBILE_CONTENT}packages/`} />
        </Helmet>
        <Navigation />
        <Banner 
            badge={PageData.title.rendered} 
            title={items[0][0][0]} 
            background={background} 
        />

        <div className="container">
            <div className="row">
                <div className="col-12 col-md-6 mx-auto text-center">
                    <HeadingTwo title={items[1][0][0]} />
                </div>
                <div className="col-12 text-center  pb-5">
                    {items[1][1][0] && <div dangerouslySetInnerHTML={{ __html: items[1][1][0] }} />}
                </div>
            </div>
        </div>

        <OwnPlan />

        <div className="container">
            <div className="row">
                <div className="col-12 mx-auto text-center pb-5">
                    <HeadingTwo title={items[2][0][0]} />
                    {items[2][1][0] && <div dangerouslySetInnerHTML={{ __html: items[2][1][0] }} />}
                </div>
            </div>
        </div>

        <AllPackages />

        <div className="container accordion-wrapper">
            {items[3][0][0] === '' ? null : <HeadingTwo title={items[3][0][0]} />}

            <Accordion 
                id="1" onClick={handleClick} isSelected={selectedChild === '1'}
                question={items[3][1][0]}
                answer={items[3][1][1]}
            />
            <Accordion 
                id="2" onClick={handleClick} isSelected={selectedChild === '2'}
                question={items[3][1][2]}
                answer={items[3][1][3]}
            />
            <Accordion 
                id="3" onClick={handleClick} isSelected={selectedChild === '3'}
                question={items[3][1][4]}
                answer={items[3][1][5]}
            />
            <Accordion 
                id="4" onClick={handleClick} isSelected={selectedChild === '4'}
                question={items[3][1][6]}
                answer={items[3][1][7]}
            />
            <Accordion 
                id="5" onClick={handleClick} isSelected={selectedChild === '5'}
                question={items[3][1][8]}
                answer={items[3][1][9]}
            />
        </div>
        <SupportCta />
    </div>
  );
};

export default Packages;