import React, { useEffect, useState } from 'react';
import {Link, useLoaderData} from "react-router-dom";
import { PageData } from "../types/PageData";
import SearchBanner from '../components/SearchBanner';
import { Helmet } from 'react-helmet-async';

import background from '../assets/images/Melon-Mobile-Banner-Background.jpg';
import HeadingHardcoded from '../components/HeadingHardcoded';
import Button from '../components/Button';
import Navigation from '../components/Navigation';

const Search = () => {
  // Page Data From Router
    const PageData = useLoaderData() as PageData;
    const items = PageData.content.no_short_codes;
    const yoast:any = PageData.yoast_head_json
    const [searchResults, setSearchResults] = useState([]);
    const [searchQuery, setSearchQuery] = useState();

    useEffect(() => {
        document.body.style.scrollBehavior = "auto";
        document.body.scrollTop = 0;
        document.documentElement.style.scrollBehavior = "auto";
        document.documentElement.scrollTop = 0;
    }, []);

    useEffect(() => {
        const results = localStorage.getItem("searchResults");
        let query:any = localStorage.getItem("searchQuery");
        setSearchQuery(query)
        if (results) {
          setSearchResults(JSON.parse(results));
          setSearchQuery(query)
        }
    }, []);

    function stripHTML(excerpt: string): string {
        const div = document.createElement("div");
        div.innerHTML = excerpt;
        return div.textContent || "";
    }

    function stripShortcodes(excerpt: string): string {
        return excerpt.replace(/\[.*?\]/g, "");
    }
    
  return (
    <div className='search-results'>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : "Search - Melon Mobile"}</title>
            <meta charSet="utf-8" />
            <meta name="description" content={yoast !== undefined ? yoast.description : "We believe the current way you’re forced to mobile is broken. With big networks offering things that really only benefit themselves. Long-term contracts, outdated plans, complicated experiences, and a one-size-fits-all approach have left most of us feeling rather bitter."}/>
            <meta property="og:description" content={yoast !== undefined ? yoast.og_description: "We believe the current way you’re forced to mobile is broken. With big networks offering things that really only benefit themselves. Long-term contracts, outdated plans, complicated experiences, and a one-size-fits-all approach have left most of us feeling rather bitter."} />
            <meta property="og:locale" content={yoast !== undefined ? yoast.og_locale : "en_US"} />
            <meta property="og:type" content={yoast !== undefined ? yoast.og_type : "article"} />
            <meta property="og:title" content={yoast !== undefined ? yoast.og_title : "Search - Melon Mobile"} />
            <meta property="og:site_name" content={yoast !== undefined ? yoast.og_site_name : "Melon Mobile"} />
            <meta property="og:url" content={yoast !== undefined ? yoast.og_url : `${process.env.REACT_APP_SECRET_MELON_MOBILE_CONTENT}search/`} />
        </Helmet>
        <Navigation />
        {searchResults.length > 0 ? (
            <div>
                <SearchBanner 
                    badge={PageData.title.rendered} 
                    title={`Search results for "${searchQuery}"`} 
                    background={background} 
                />
                <div className="container">
                    {
                        searchResults.map((result:any) => (
                            <div className='result' key={result.id}>
                                <HeadingHardcoded title={result.title.rendered} />
                                <div className='excerpt'>
                                    <p>{stripHTML(stripShortcodes(result.excerpt.rendered))}</p>
                                </div>
                                <div className="link">
                                    <Button 
                                        linkText='Take me there!'
                                        link={'/'+result.slug}
                                        type='button'
                                        className='btn btn-primary'
                                    />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            ) : (
            <div className='no-search-results'>
                <SearchBanner 
                    badge={PageData.title.rendered} 
                    title={`Search results for “${searchQuery}”` }
                    background={background} 
                />
                <div className="container">
                    <div className='text-center no-results'>
                        <HeadingHardcoded title='Nothing Found.' />
                        <p>It seems we can’t find what you’re looking for.</p>
                        <p>You can hang out here if you want, or use the links below to go somewhere more interesting.</p>
                        <Button 
                            className='btn btn-primary'
                            link='/help-center'
                            linkText='Explore our help center'
                            type='button'
                        />
                    </div>
                </div>
            </div>
        )}
    </div>
  );
}

export default Search;