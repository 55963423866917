const lazyLoadImage = ( content: any ) => {

    if ( content && content.includes('<img') ) {
        content = content.replace("/>", "loading='lazy' />");
    }
    

    return content;
}

export default lazyLoadImage