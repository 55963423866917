import React, { useEffect } from 'react';
import {useLoaderData} from "react-router-dom";
import { PageData } from "../types/PageData";
import { Helmet } from 'react-helmet-async';
import Banner from '../components/Banner';

import background from '../assets/images/Melon-Mobile-Banner-Background.jpg';
import HeadingTwo from '../components/HeadingTwo';
import Navigation from '../components/Navigation';

const TermsAndConditions = () => {
  // Page Data From Router
    const PageData = useLoaderData() as PageData
    const items = PageData.content.no_short_codes;
    const yoast:any = PageData.yoast_head_json


  useEffect(() => {
        document.body.style.scrollBehavior = "auto";
        document.body.scrollTop = 0;
        document.documentElement.style.scrollBehavior = "auto";
        document.documentElement.scrollTop = 0;
    }, []);

    console.log(items)

  return (
    <div>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : "Terms & Conditions - Melon Mobile"}</title>
            <meta charSet="utf-8" />
            <meta name="description" content={yoast !== undefined ? yoast.description : "Melon Mobile welcome you as a user of the services provided via this website, web-based application and/or mobile application (the “Application”). We are providing the services to you, subject to theseTerms of Use and the, “Privacy Policy” ,(collectively known as the “Terms”)."}/>
            <meta property="og:description" content={yoast !== undefined ? yoast.og_description: "Melon Mobile welcome you as a user of the services provided via this website, web-based application and/or mobile application (the “Application”). We are providing the services to you, subject to theseTerms of Use and the, “Privacy Policy” ,(collectively known as the “Terms”)."} />
            <meta property="og:locale" content={yoast !== undefined ? yoast.og_locale : "en_US"} />
            <meta property="og:type" content={yoast !== undefined ? yoast.og_type : "article"} />
            <meta property="og:title" content={yoast !== undefined ? yoast.og_title : "Terms & Conditions - Melon Mobile"} />
            <meta property="og:site_name" content={yoast !== undefined ? yoast.og_site_name : "Melon Mobile"} />
            <meta property="og:url" content={yoast !== undefined ? yoast.og_url : `${process.env.REACT_APP_SECRET_MELON_MOBILE_CONTENT}legal/`} />
        </Helmet>
        <Navigation />
        <Banner 
            badge={PageData.title.rendered} 
            title={items[0][0]} 
            background={background} 
        />
        <div className="container legal-content">
            <div className="row">
                <div className="col-12">
                    {items[1][0][0] && <HeadingTwo title={items[1][0][0]} />}
                    {items[1][0][0] && <HeadingTwo title={items[1][1].join('')} />}
                    {/* {items[1][1] && <div dangerouslySetInnerHTML={{ __html: items[1][1].join('') }} />} */}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {/* {items[2][0][0] && <HeadingTwo title={items[2][0][0]} />} */}
                    {items[2][1] && <div dangerouslySetInnerHTML={{ __html: items[2][0][0] }} />}
                    {items[2][1] && <div dangerouslySetInnerHTML={{ __html: items[2][1].join('') }} />}
                    {items[2][1] && <div dangerouslySetInnerHTML={{ __html: items[2][2][0] }} />}
                    {items[2][1] && <div dangerouslySetInnerHTML={{ __html: items[2][3][0] }} />}
                    {/* {items[2][2] && <div dangerouslySetInnerHTML={{ __html: items[2][2].paragraphs[0] }} />} */}
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    {items[3][0][0] && <HeadingTwo title={items[3][0][0]} />}
                    {items[3][1] && <div dangerouslySetInnerHTML={{ __html: items[3][1].join('') }} />}
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    {items[4][0][0] && <HeadingTwo title={items[4][0][0]} />}
                    {items[4][1] && <div dangerouslySetInnerHTML={{ __html: items[4][1].join('') }} />}
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    {items[5][0][0] && <HeadingTwo title={items[5][0][0]} />}
                    {items[5][1] && <div dangerouslySetInnerHTML={{ __html: items[5][1].join('') }} />}
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    {items[6][0][0] && <HeadingTwo title={items[6][0][0]} />}
                    {items[6][1] && <div dangerouslySetInnerHTML={{ __html: items[6][1].join('') }} />}
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    {items[7][0][0] && <HeadingTwo title={items[7][0][0]} />}
                    {items[7][1] && <div dangerouslySetInnerHTML={{ __html: items[7][1].join('') }} />}
                </div>
            </div>

            {/* <div className="row">
                <div className="col-12">
                    {items[8][0][0] && <HeadingTwo title={items[8][0][0]} />}
                    {items[8][1] && <div dangerouslySetInnerHTML={{ __html: items[8][1].join('') }} />}
                </div>
            </div> */}

            {/* <div className="row">
                <div className="col-12">
                    {items[9][0][0] && <HeadingTwo title={items[9][0][0]} />}
                    {items[9][1] && <div dangerouslySetInnerHTML={{ __html: items[9][1].join('') }} />}
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    {items[10][0][0] && <HeadingTwo title={items[10][0][0]} />}
                    {items[10][1] && <div dangerouslySetInnerHTML={{ __html: items[10][1].join('') }} />}
                </div>
            </div> */}
        </div>
    </div>
  );
};

export default TermsAndConditions;