import React from 'react'
import HeadingHardcoded from './HeadingHardcoded';
import background from '../assets/images/Melon-Mobile-Contact-Banner.svg';
interface BannerProps {
    title: string,
    postDate: string,
    image: string,
    imageName: string,
}
function SingleBlogBanner({...props}: BannerProps) {
  return (
    <div className='single-post-banner' style={{backgroundImage: `url(${background})`}}>
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-6">
                    {props.postDate === '' ? null : <p>{props.postDate}</p>}
                    {props.title === '' ? null : <HeadingHardcoded title={props.title} />}
                </div>
            </div>
        </div>
        {props.image === '' ? null : <img src={props.image} alt={props.imageName} />}
    </div>
  )
}

export default SingleBlogBanner