import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { hydrateRoot } from 'react-dom/client';
import { createRoot } from 'react-dom/client';

const domNode: any = document.getElementById('root');
const root = createRoot(domNode);

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// )
// ReactDOM.render(<App />, document.getElementById("root"));
const rootElement: any = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrateRoot(domNode, rootElement);
} else {
  // ReactDOM.render(<App />, rootElement);
  // root.render(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
