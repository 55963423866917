import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie';
import { useInView } from 'react-intersection-observer';

//Components
import HeadingTwo from './HeadingTwo';
import Button from './Button';

//Animations
import moreChoice from '../assets/lottie/melon-mobile-more-choice-animation.json';
// import mtnPop from '../assets/lottie/melon-mobile-mtn-pop-animation.json';
import fullyDigital from '../assets/lottie/melon-mobile-fully-digital-animation.json';
import noContracts from '../assets/lottie/melon-mobile-no-contracts-animation.json';
import dataNeverExpires from '../assets/lottie/melon-mobile-data-that-never-expires-animation.json';

interface benefitsProps {
    title: string,
    content1: string,
    content2: string,
    content3: string,
    content4: string,
    subTitle1: string,
    subTitle2: string,
    subTitle3: string,
    subTitle4: string,
    image1: string,
    image2: string,
    image3: string,
    image4: string,
    link: string,
    linkText: string
}

function Benefits({...props}: benefitsProps) {
    const [inView, setInView] = useState(false);
    const headingRef = React.useRef<HTMLImageElement>(null);
    const lottieRef = React.useRef<HTMLImageElement>(null);
    const [ref, inView2] = useInView({
        triggerOnce: true,
        rootMargin: '200px'
      });

      useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (lottieRef.current) {
          observer.observe(lottieRef.current);
        }
        return () => {
          observer.disconnect();
        };
    }, [lottieRef]);

    //More Choice
    const moreChoiceOptions = {
        loop: true,
        autoplay: true, 
        animationData: moreChoice,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    //Data that never expires
    const dataThatNeverExpiresOptions = {
        loop: true,
        autoplay: true, 
        animationData: dataNeverExpires,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

     //Fully Digital
     const fullyDigitalOptions = {
        loop: true,
        autoplay: true, 
        animationData: fullyDigital,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    //No contracts
    const noContactsOptions = {
        loop: true,
        autoplay: true, 
        animationData: noContracts,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (headingRef.current) {
          observer.observe(headingRef.current);
        }
        return () => {
          observer.disconnect();
        };
      }, [headingRef]);
  return (
    <div className='benefits'>
        <div className="bg"></div>
        <div className="container">
            {props.title && <HeadingTwo title={props.title} />}
            <div className="row">
                <div className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
                    <div className="benefit">
                        <div ref={lottieRef}>
                            {props.image1 && <div className='d-lg-none' dangerouslySetInnerHTML={{ __html: props.image1 }} />}
                            <div className='d-none d-lg-block lottie-image' ref={ref}>
                                <Lottie options={moreChoiceOptions} height={150} width={150} isStopped={!inView} />
                            </div>
                        </div>
                        {props.subTitle1 && <div dangerouslySetInnerHTML={{ __html: props.subTitle1 }} />}
                        {props.content1 && <div dangerouslySetInnerHTML={{ __html: props.content1 }} />}
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
                    <div className="benefit">
                        <div ref={lottieRef}>
                            {props.image2 && <div className='d-lg-none' dangerouslySetInnerHTML={{ __html: props.image2 }} />}
                            <div className='d-none d-lg-block lottie-image' ref={ref}>
                                <Lottie options={dataThatNeverExpiresOptions} height={150} width={150} isStopped={!inView} />
                            </div>
                        </div>
                        {props.subTitle2 && <div dangerouslySetInnerHTML={{ __html: props.subTitle2 }} />}
                        {props.content2 && <div dangerouslySetInnerHTML={{ __html: props.content2 }} />}
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
                    <div className="benefit">
                        <div ref={lottieRef}>
                            {props.image3 && <div className='d-lg-none' dangerouslySetInnerHTML={{ __html: props.image3 }} />}
                            <div className='d-none d-lg-block lottie-image' ref={ref}>
                                <Lottie options={fullyDigitalOptions} height={150} width={150} isStopped={!inView} />
                            </div>
                        </div>
                        {props.subTitle3 && <div dangerouslySetInnerHTML={{ __html: props.subTitle3 }} />}
                        {props.content3 && <div dangerouslySetInnerHTML={{ __html: props.content3 }} />}
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
                    <div className="benefit">
                        <div ref={lottieRef}>
                            {props.image4 && <div className='d-lg-none' dangerouslySetInnerHTML={{ __html: props.image4 }} />}
                            <div className='d-none d-lg-block lottie-image' ref={ref}>
                                <Lottie options={noContactsOptions} height={150} width={150} isStopped={!inView} />
                            </div>
                        </div>
                        {props.subTitle4 && <div dangerouslySetInnerHTML={{ __html: props.subTitle4 }} />}
                        {props.content4 && <div dangerouslySetInnerHTML={{ __html: props.content4 }} />}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 ai-pt-4 text-center">
                    {props.link === '' ? null : <Button type='button' className='btn btn-primary' linkText={props.linkText} link={props.link} />}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Benefits