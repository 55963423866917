import React, { useEffect, useState } from 'react';

//Images
import whatsapp from '../assets/images/icons/Melon-Mobile-Whatsapp.svg';
import appStore from '../assets/images/icons/app-store-ios.svg';
import googlePlay from '../assets/images/icons/logo-google-playstore.svg';
import background from '../assets/images/Melon-Mobile-Contact-Banner.svg';
import chatBubble from '../assets/images/icons/Melon-Mobile-Contact-Us-Chat-Bubble.svg'
import { Link } from 'react-router-dom';
import HeadingOne from "./HeadingOne";

//Icons
import { RiLinkedinBoxFill } from 'react-icons/ri';
import { RiTwitterFill } from 'react-icons/ri';
import { RiInstagramFill } from 'react-icons/ri';
import { RiWhatsappLine } from 'react-icons/ri';
import { FaFacebook } from 'react-icons/fa';
import { FaTiktok } from 'react-icons/fa';

interface contactProps {
    title: string,
    subtitle: string,
    whatsappLink: string,
    facebookLink: string,
    twitterLink: string,
    linkedinLink: string,
    tiktokLink: string,
    appStoreLink: string,
    googlePlayLink: string,
    instagramLink: string
}

function ContactBanner({...props}: contactProps) {
    const [inView, setInView] = useState(false);
    const headingRef = React.useRef<HTMLImageElement>(null);
    
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (headingRef.current) {
          observer.observe(headingRef.current);
        }
        return () => {
          observer.disconnect();
        };
      }, [headingRef]);
  return (
    <div className='contact-banner' style={{backgroundImage: `url(${background})`}}>
        <div className="container">
            <div>
                <div className="row py-0">
                    <div className="col-12 col-md-8 col-lg-5">
                        <div ref={headingRef}
                        className={`reveal-heading ${inView ? 'slide-up' : ''}`}>{props.title === '' ? null : <HeadingOne title={props.title}/>}</div>
                        <div className="col-12 col-md-6 chat-bubble-icon">
                            <img src={chatBubble} alt="Melon Mobile Chat Bubble" />
                        </div>
                    </div>
                </div>
                <div className="row py-0">
                    <div className="col-12 col-md-6">
                        {props.subtitle === '' ? null : <p>{props.subtitle}</p>}
                    </div>
                    
                </div>
                <div className="row pb-0">
                    {props.whatsappLink === '' ? null : 
                    <div className="col-12 col-md-4 whatsapp">
                        <h5>Message us on Whatsapp</h5>
                        <p> <a href={'https://wa.me/'+props.whatsappLink} target={'_blank'} rel={'noreferrer'}><RiWhatsappLine /> Chat with us</a></p>
                    </div>}
                    <div className="col-12 col-md-4 socials">
                        <h5>Connect on our socials</h5>
                        <p>
                            {props.facebookLink === '' ? null :
                            <a href={props.facebookLink} target={'_blank'} rel={'noreferrer'}>
                                <FaFacebook />
                            </a>}
                            {props.twitterLink === '' ? null :
                            <a href={props.twitterLink} target={'_blank'} rel={'noreferrer'}>
                                <RiTwitterFill />
                            </a>}
                            {props.linkedinLink === '' ? null :
                            <a href={props.linkedinLink} target={'_blank'} rel={'noreferrer'}>
                                <RiLinkedinBoxFill />
                            </a>}
                            {props.tiktokLink === '' ? null :
                            <a href={props.tiktokLink} target={'_blank'} rel={'noreferrer'}>
                                <FaTiktok />
                            </a>}
                            {props.instagramLink === '' ? null :
                            <a href={props.instagramLink} target={'_blank'} rel={'noreferrer'}>
                                <RiInstagramFill />
                            </a>}
                        </p>
                    </div>
                    <div className="col-12 col-md-4 stores">
                        <h5>Download the Melon App</h5>
                        <p> 
                            {props.appStoreLink === '' ? null :
                            <a href={props.appStoreLink} target={'_blank'} rel={'noreferrer'}>
                                <img src={appStore} alt="App Store" />
                            </a>}
                            {props.googlePlayLink === '' ? null :
                            <a href={props.googlePlayLink} target={'_blank'} rel={'noreferrer'}>
                                <img src={googlePlay} alt="Google Play" />
                            </a>}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ContactBanner