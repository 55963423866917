
const convertTextToLinkFriendly = ( name: string ) => {
    const regex = / /ig;
    return name.toLowerCase().replace(regex, '-');
}

const convertMinutes = ( timeMetrix: string ) => {

    const regexMinutes = /Minutes/ig;
    if( timeMetrix ){
        return timeMetrix.replace(regexMinutes, 'Mins');
    }
}

const createPlannedPackageValues: any = async ( singlePackageData: any ) => {
    let responseObj: any = {};

    return new Promise( ( resolve, reject ) => {
         if ( singlePackageData && singlePackageData.length > 0 && Array.isArray(singlePackageData) ) {
             singlePackageData.map( async ( singlePackageCharacteristic: any, singlePackageCharacteristicIndex: any ) => {
                 
                 if ( singlePackageCharacteristic && singlePackageCharacteristic.name === 'Voice' ) {
                     responseObj['call'] = singlePackageCharacteristic.productSpecCharacteristicValue[0].value + ' ' + singlePackageCharacteristic.productSpecCharacteristicValue[0].unitOfMeasure;
                 }

                 if ( singlePackageCharacteristic && singlePackageCharacteristic.name === 'Data' ) {
                     responseObj['data'] = singlePackageCharacteristic.productSpecCharacteristicValue[0].value + ' ' + singlePackageCharacteristic.productSpecCharacteristicValue[0].unitOfMeasure;
                 }
                 
                 if ( singlePackageCharacteristic && singlePackageCharacteristic.name === 'Text' ) {
                     responseObj['text'] = singlePackageCharacteristic.productSpecCharacteristicValue[0].value + ' ' + singlePackageCharacteristic.productSpecCharacteristicValue[0].unitOfMeasure;
                 }

             });
         }

         resolve(responseObj);
    });

 }

// Creating the package price.
const createPackagePrice = ( packagePricces: any ) => {

    let priceValue = 0;

    if( packagePricces ) {
        packagePricces.map( ( sinlgePackagePriceObj: any , sinlgePackagePriceIndex: any ) => {
            // console.log(sinlgePackagePriceObj['@type']);
            if( sinlgePackagePriceObj && sinlgePackagePriceObj.recurringChargePeriodType === 'month' ) {
                priceValue = sinlgePackagePriceObj.price.value;
            }
        });
    }

    return priceValue;
}

// Return currency value.
const currencyFormatter = new Intl.NumberFormat('en-ZA', {
    style: 'currency',
    currency: 'ZAR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

// Return order by price 
const sort_order_by_price = ( arrayObj: any ) => {
    let orderedValues = arrayObj.sort((a:any, b:any) => (a.price > b.price ? 1 : -1));
    // console.log(orderedValues);
    return orderedValues;
}

const return_by_selection_sorted_by_price = ( arrayObj: any, selected_options: any ) => {
    // let selectedOptionsOnly = arrayObj.map( (option: any) => { if (selected_options.includes(option.title)) { return option;} else {} });
    let selectedOptionsOnly = arrayObj.filter( (option: any) =>  ( (selected_options.includes(option.title)) ));
    // console.log(selectedOptionsOnly);
    // console.log(selected_options);
    return sort_order_by_price(selectedOptionsOnly);
}

export { convertTextToLinkFriendly, convertMinutes, createPlannedPackageValues, createPackagePrice, currencyFormatter, sort_order_by_price, return_by_selection_sorted_by_price };