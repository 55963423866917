import React, { useState, useEffect } from 'react';
import { useLoaderData, useParams, useSearchParams, useLocation } from "react-router-dom";
import PackageBanner from '../components/PackageBanner';
import { PageData } from "../types/PageData";
import ContentImage from '../components/ContentImage';
import image2 from '../assets/images/Melon-Mobile-Switch-To-Melon-CTA-Phone.png';
import DownloadCtaRed from '../components/DownloadCtaRed';
import Accordion from '../components/Accordion';
import SupportCta from '../components/SupportCta';
import HeadingTwo from '../components/HeadingTwo';
import { Helmet } from 'react-helmet-async';
import Navigation from '../components/Navigation';

// Custom functions
import { fetchPackages } from '../functions/fetchDataAPI';
import { convertTextToLinkFriendly, convertMinutes, createPlannedPackageValues, createPackagePrice } from '../helpers/generalFormating';


const PackageSingle = ( props: any ) => {
    const [selectedChild, setSelectedChild] = useState<string | null>(null);

    const handleClick = (childId: string) => {
      setSelectedChild(childId);
    };

    // Page Data From Router
    const [PageData] = useLoaderData() as PageData[];
    const items = PageData?.content?.no_short_codes;
    const yoast:any = PageData?.yoast_head_json;
    const params = useParams();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [packgeInfo, setPackageInfo]: any = useState({});
    // setSearchParams(location.search);


  const ctaRedData:any = {
        title:'#MoveToMelon',
        copy: '<p>Download the Melon App and <br> say hello to mobile freedom</p>',
        image: `${process.env.REACT_APP_SECRET_MELON_MOBILE_CONTENT}wp-content/uploads/2023/03/Melon-Mobile-Move-To-Melon-1.jpg`
    }

    useEffect(() => {
   
        scrollToTop();

        // Fetch the single package data from the API
        fetchOnePageData();

    }, []);

    useEffect( () => {
        console.log('Changed');
        scrollToTop();

         // Fetch the single package data from the API
         fetchOnePageData();

    }, [items]);

    // Fetch the single package data.
    const fetchOnePageData = async () => {

        let id = searchParams.get('id');
        let slug = params;

        console.log( 'Slug: ', slug, 'Id: ', id );

        // Get the id based on the slug name.

        if ( slug && slug.slug ) {
            let selected_slug = slug.slug;
            let slugNameCleaned = cleanSlugName(selected_slug);

            await fetchPackages('productOffering?fields=*&channel.id=a3cd8ce4-9259-4b14-8b17-b3e658ef0dbd&isSellable=true&offerType=Plan&expand=productOfferingPrice')
                .then( async ( allPackages: any ) => {
                    let currentPackage = returnActivePackage( allPackages, slugNameCleaned);
                    if( currentPackage && currentPackage.length > 0 ) {
                        let singlePackageObj: any = {};
                        let packagesValues: any = await createPlannedPackageValues(currentPackage[0].prodSpecCharValueUse);
                        let packagePrice: any = currentPackage[0].productOfferingPrice ? createPackagePrice(currentPackage[0].productOfferingPrice) : 0;

                        singlePackageObj['title'] = currentPackage[0].name === 'Build Your Own Plan' || currentPackage[0].name === 'Try Before You Buy' ? currentPackage[0].name : currentPackage[0].name + " Melon";
                        singlePackageObj['id'] = currentPackage[0].id;
                        singlePackageObj['data'] = packagesValues ? packagesValues.data : 0;
                        singlePackageObj['call'] = packagesValues ? convertMinutes(packagesValues.call) : 0;
                        singlePackageObj['text'] = packagesValues ? packagesValues.text : 0;
                        singlePackageObj['price'] = currentPackage[0].name === 'Try Before You Buy' ? "FREE" : "R "+ packagePrice;

                        console.log(currentPackage);

                        if( Object.keys( singlePackageObj ).length > 0 ) {
                            setPackageInfo(singlePackageObj);
                        }
                    }
                })
                .catch( (error: any) => { console.error(error); });
        } else {
            setPackageInfo({});
        }

        // Then fetch the id based on the slug name.


        // if ( id && id.length > 0 ) {
        //     await fetchPackages( '/productOffering/' + id )
        //     .then( async ( singleProduct: any ) => {

        //         let singlePackageObj: any = {};
        //         let packagesValues: any = await createPlannedPackageValues(singleProduct.prodSpecCharValueUse);
        //         let packagePrice: any = singleProduct.productOfferingPrice ? createPackagePrice(singleProduct.productOfferingPrice) : 0;

        //         singlePackageObj['title'] = singleProduct.name === 'Build Your Own Plan' || singleProduct.name === 'Try Before You Buy' ? singleProduct.name : singleProduct.name + " Melon";
        //         singlePackageObj['id'] = singleProduct.id;
        //         singlePackageObj['data'] = packagesValues ? packagesValues.data : 0;
        //         singlePackageObj['call'] = packagesValues ? convertMinutes(packagesValues.call) : 0;
        //         singlePackageObj['text'] = packagesValues ? packagesValues.text : 0;
        //         singlePackageObj['price'] = singleProduct.name === 'Try Before You Buy' ? "FREE" : "R "+ packagePrice;

        //         if( Object.keys( singlePackageObj ).length > 0 ) {
        //             setPackageInfo(singlePackageObj);
        //         }

        //     })
        //     .catch( error => console.error( error ) )
        // } else {
        //     setPackageInfo({});
        // }
    }

    const cleanSlugName = ( name: string ) => {

        return name.replaceAll( '-melon', '');
    }


    const returnActivePackage = ( allPackages: any, activePackageName: string ) => {
        let activePackageData: any = [];

        allPackages.map( ( activePackageOne: any , activePackageOneIndex: any ) => {

            let packageNameConverted = convertTextToLinkFriendly(activePackageOne.name);

            if( packageNameConverted === activePackageName ){
                activePackageData.push(activePackageOne);
                return false;
            }
        });

        return activePackageData;
    }

    const scrollToTop = () => {
        document.body.style.scrollBehavior = "auto";
        document.body.scrollTop = 0;
        document.documentElement.style.scrollBehavior = "auto";
        document.documentElement.scrollTop = 0;
    }

  return (
      <div>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : PageData.title.rendered + ' - Melon Mobile'}</title>
            <meta charSet="utf-8" />
            <meta name="description" content={yoast !== undefined ? yoast.description : "Melon gives you the freedom to go your own way. You can build your own plan or select a plan that works for you."}/>
            <meta property="og:description" content={yoast !== undefined ? yoast.og_description: "Melon gives you the freedom to go your own way. You can build your own plan or select a plan that works for you."} />
            <meta property="og:locale" content={yoast !== undefined ? yoast.og_locale : "en_US"} />
            <meta property="og:type" content={yoast !== undefined ? yoast.og_type : "article"} />
            <meta property="og:title" content={yoast !== undefined ? yoast.og_title : PageData.title.rendered + ' - Melon Mobile'} />
            <meta property="og:site_name" content={yoast !== undefined ? yoast.og_site_name : "Melon Mobile"} />
            <meta property="og:image" content={yoast !== undefined ? yoast.og_image[0].url: "http://melonmobilecontent.agletinteractive.com/wp-content/uploads/2023/01/Melon-Mobile-Data-Dark-Icon.svg"} />
            <meta property="og:url" content={yoast !== undefined ? yoast.og_url : "https://melonmobilecontent.agletinteractive.com/packages/student-melon/"} />
        </Helmet>
        <Navigation />
        <PackageBanner
            id={packgeInfo.id}
            background={items[0][0]}
            icon={items[0][1]}
            title={Object.keys(packgeInfo).length > 0 ? packgeInfo.title : items[0][2][0] }
            price={Object.keys(packgeInfo).length > 0 ? packgeInfo.price : 'No data'}
            paragraph={items[0][3][1]}
            // includes={[items[0][4][2],items[0][4][5],items[0][4][8]]}
            includes={[Object.keys(packgeInfo).length > 0 ? '' + packgeInfo.data : 0, Object.keys(packgeInfo).length > 0 ? packgeInfo.call : 0, Object.keys(packgeInfo).length > 0 ? packgeInfo.text.replaceAll('SMS', 'Texts') : 0]}
        />

        <div className="with-icon">
            <ContentImage 
                layout='image right'
                image={items[2][0]}
                title={items[2][1]}
                copy={items[2][2]}
                list=''
                link=''
                linkText=''
                numbers=''
            />
        </div>

        <div className="with-icon">
            <ContentImage 
                layout='image left'
                image={items[3][0]}
                title={items[3][1]}
                copy={items[3][2]}
                list=''
                link=''
                linkText=''
                numbers=''
                animation = {true}
                animationName = 'still-searching'
            />
        </div>

        <div className="with-icon">
            <ContentImage 
                layout='image right'
                image={items[4][0]}
                title={items[4][1]}
                copy={items[4][2]}
                list=''
                link=''
                linkText=''
                numbers=''
                animation = {true}
                animationName = 'available-in-sa'
            />
        </div>

        <DownloadCtaRed 
            title={ctaRedData.title}
            image={ctaRedData.image}
            copy={ctaRedData.copy}
        />

        <SupportCta />

        <div className="container accordion-wrapper">
            {items[5][0][0] === '' ? null : <HeadingTwo title={items[5][0][0]} />}

            <Accordion 
                id="1" onClick={handleClick} isSelected={selectedChild === '1'}
                question={items[5][1][0]}
                answer={items[5][1][1]}
            />
            <Accordion 
                id="2" onClick={handleClick} isSelected={selectedChild === '2'}
                question={items[5][1][2]}
                answer={items[5][1][3]}
            />
            <Accordion 
                id="3" onClick={handleClick} isSelected={selectedChild === '3'}
                question={items[5][1][4]}
                answer={items[5][1][5]}
            />
            <Accordion 
                id="4" onClick={handleClick} isSelected={selectedChild === '4'}
                question={items[5][1][6]}
                answer={items[5][1][7]}
            />
            <Accordion 
                id="5" onClick={handleClick} isSelected={selectedChild === '5'}
                question={items[5][1][8]}
                answer={items[5][1][9]}
            />
        </div>
      </div>
  );
};


export default PackageSingle;