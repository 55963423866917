import React, { useEffect, useState } from 'react';
import {useLoaderData} from "react-router-dom";
import { PageData } from "../types/PageData";
import Banner from '../components/Banner';
import background from '../assets/images/Melon-Mobile-Banner-Background.jpg';
import appStore from '../assets/images/icons/Melon-Mobile-Download-App-Store.svg';
import googlePlay from '../assets/images/icons/Melon-Mobile-Download-Google-Play.svg';
import ContentImage from '../components/ContentImage';
import MelonSteps from '../components/MelonSteps';
import Accordion from '../components/Accordion';
import Testimonials from '../components/Testimonials';
import { Helmet } from 'react-helmet-async';

import image1 from '../assets/images/Melon-Mobile-Your-Plan-Made-Your-Way-CTA.png';
import DownloadCta from '../components/DownloadCta';
import SupportCta from '../components/SupportCta';
import HeadingTwo from '../components/HeadingTwo';
import HeadingHardcoded from '../components/HeadingHardcoded';
import Navigation from '../components/Navigation';

const DownloadApp = () => {
    const [inView, setInView] = useState(false);
    const imgRef = React.useRef<HTMLImageElement>(null);
    const [inView2, setInView2] = useState(false);
    const imgRef2 = React.useRef<HTMLImageElement>(null);
    const [inView3, setInView3] = useState(false);
    const imgRef3 = React.useRef<HTMLImageElement>(null);
    const [selectedChild, setSelectedChild] = useState<string | null>(null);

      const handleClick = (childId: string) => {
        setSelectedChild(childId);
    };
    // Page Data From Router
    const PageData = useLoaderData() as PageData
    const items = PageData.content.no_short_codes
    const yoast:any = PageData.yoast_head_json


  const ctaData:any = {
        title:'It’s your plan made your way.',
        copy: '<p>The first step to digital freedom takes less than a minute. Download the Melon App now.</p>',
        image: image1
    }

    useEffect(() => {
        document.body.style.scrollBehavior = "auto";
        document.body.scrollTop = 0;
        document.documentElement.style.scrollBehavior = "auto";
        document.documentElement.scrollTop = 0;
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (imgRef.current) {
          observer.observe(imgRef.current);
        }
        return () => {
          observer.disconnect();
        };
    }, [imgRef]);
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView2(true);
            }
          });
        });
        if (imgRef2.current) {
          observer.observe(imgRef2.current);
        }
        return () => {
          observer.disconnect();
        };
    }, [imgRef2]);
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView3(true);
            }
          });
        });
        if (imgRef3.current) {
          observer.observe(imgRef3.current);
        }
        return () => {
          observer.disconnect();
        };
    }, [imgRef3]);

    return (
        <div className='download-app'>
            <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : "Download app - Melon Mobile"}</title>
            <meta charSet="utf-8" />
            <meta name="description" content={yoast !== undefined ? yoast.description : "We’re 100% digital, which means you’re always in control of your mobile plan. Build and change your plan, self-RICA, manage your account, and ask for help if you need it. Super simple, super sweet."}/>
            <meta property="og:description" content={yoast !== undefined ? yoast.og_description: "We’re 100% digital, which means you’re always in control of your mobile plan. Build and change your plan, self-RICA, manage your account, and ask for help if you need it. Super simple, super sweet."} />
            <meta property="og:locale" content={yoast !== undefined ? yoast.og_locale : "en_US"} />
            <meta property="og:type" content={yoast !== undefined ? yoast.og_type : "article"} />
            <meta property="og:title" content={yoast !== undefined ? yoast.og_title : "Download app - Melon Mobile"} />
            <meta property="og:site_name" content={yoast !== undefined ? yoast.og_site_name : "Melon Mobile"} />
            <meta property="og:image" content={yoast !== undefined ? yoast.og_image[0].url: `${process.env.REACT_APP_SECRET_MELON_MOBILE_CONTENT}wp-content/uploads/2023/01/Melon-Mobile-Green-Tick.svg`} />
            <meta property="og:url" content={yoast !== undefined ? yoast.og_url : `${process.env.REACT_APP_SECRET_MELON_MOBILE_CONTENT}download-app/`} />
            </Helmet>
            <Navigation />
            <Banner 
                badge={PageData.title.rendered} 
                title={items[0][0][0]+'<br />'+items[0][0][1]+items[0][0][2]} 
                background={background} 
            />

            <div className="container ai-large-image-banner">
                <div className="row">
                    <div className="col-12 col-lg-9 mx-auto">
                        {items[1][0] === '' ? null : <div ref={imgRef}
                        className={`image-reveal text-center ${inView ? 'slide-up' : ''}`} dangerouslySetInnerHTML={{ __html: items[1][0] }} />}
                    </div>
                    <div className="col-12 col-md-11 mx-auto">
                        {items[1][1][0] === '' ? null : <HeadingTwo title={items[1][1][0]} />}
                        {items[1][1][1] === '' ? null : <div className='text-center' dangerouslySetInnerHTML={{ __html: items[1][1][1] }} />}
                    </div>
                </div>
                <div className="download-links">
                    <a href="https://apps.apple.com/bg/app/melon-mobile/id1659235762" target='_blank' rel='noreferrer'>
                        <img src={appStore} alt="App store download" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.shinemobileapplication.melon" target='_blank' rel='noreferrer'>
                        <img src={googlePlay} alt="Google play store" />
                    </a>
                </div>
            </div>

            <div className='container content-image'>
                <div className='row image-left'>
                    <div className="col-12 col-md-6 image">
                        {items[2][0] === '' ? null : <div ref={imgRef2}
                        className={`image-reveal ${inView2 ? 'slide-up' : ''}`} dangerouslySetInnerHTML={{ __html: items[2][0] }} />}
                    </div>

                    <div className="col-12 col-md-6 text">
                        {items[2][1] === '' ? null : <HeadingHardcoded title={items[2][1]} />}
                        {items[2][2] === '' ? null : <p>{items[2][2]}</p>}
                        {items[2][3] === '' ? null : <div className='list-item' dangerouslySetInnerHTML={{ __html: items[2][3][0]+items[2][3][1] }} />}
                        {items[2][3] === '' ? null : <div className='list-item' dangerouslySetInnerHTML={{ __html: items[2][3][2]+items[2][3][3] }} />}
                        {items[2][3] === '' ? null : <div className='list-item' dangerouslySetInnerHTML={{ __html: items[2][3][4]+items[2][3][5] }} />}
                        {items[2][3] === '' ? null : <div className='list-item' dangerouslySetInnerHTML={{ __html: items[2][3][6]+items[2][3][7] }} />}
                        {items[2][3] === '' ? null : <div className='list-item' dangerouslySetInnerHTML={{ __html: items[2][3][8]+items[2][3][9] }} />}
                    </div>
                </div>
            </div>

            <div className='container content-image'>
                <div className='row image-right'>
                    <div className="col-12 col-md-6 text">
                        {items[3][1] === '' ? null : <HeadingHardcoded title={items[3][1]} />}
                        {items[3][2] === '' ? null : <p>{items[3][2]}</p>}
                        {items[3][3] === '' ? null : <div className='list-item' dangerouslySetInnerHTML={{ __html: items[3][3][0]+items[3][3][1] }} />}
                        {items[3][3] === '' ? null : <div className='list-item' dangerouslySetInnerHTML={{ __html: items[3][3][2]+items[3][3][3] }} />}
                        {items[3][3] === '' ? null : <div className='list-item' dangerouslySetInnerHTML={{ __html: items[3][3][4]+items[3][3][5] }} />}
                        {items[3][3] === '' ? null : <div className='list-item' dangerouslySetInnerHTML={{ __html: items[3][3][6]+items[3][3][7] }} />}
                        {/* {items[3][3] === '' ? null : <div className='list-item' dangerouslySetInnerHTML={{ __html: items[3][3][8]+items[3][3][9] }} />} */}
                    </div>

                    <div className="col-12 col-md-6 image">
                        {items[3][0] === '' ? null : <div ref={imgRef3}
                        className={`image-reveal ${inView3 ? 'slide-up' : ''}`} dangerouslySetInnerHTML={{ __html: items[3][0] }} />}
                    </div>
                </div>
            </div>

            <div className="with-icon">
                <ContentImage 
                    layout='image left'
                    image={items[4][0]}
                    title={items[4][1]}
                    copy={items[4][2]}
                    link=''
                    linkText=''
                    list=''
                    numbers=''
                    animation = {true}
                    animationName = 'available-in-sa'
                />
            </div>

            <MelonSteps 
                title={items[5][0][0]} 
                introSubtitle='' 
                image1={items[5][1][0]} 
                image2={items[5][3][0]} 
                image3={items[5][5][0]} 
                copy1={items[5][2][0]} 
                copy2={items[5][4][0]} 
                copy3={items[5][6][0]} 
                link='' 
                linkText=''
            />

            <DownloadCta 
                title={ctaData.title} 
                copy={ctaData.copy} 
                image={ctaData.image} 
            />

            <div className="container accordion-wrapper">
                {items[6][0][0] === '' ? null : <HeadingTwo title={items[6][0][0]} />}

                <Accordion 
                    id="1" onClick={handleClick} isSelected={selectedChild === '1'}
                    question={items[6][1][0]}
                    answer={items[6][1][1]}
                />
                <Accordion 
                    id="2" onClick={handleClick} isSelected={selectedChild === '2'}
                    question={items[6][1][2]}
                    answer={items[6][1][3]}
                />
                <Accordion
                    id="3" onClick={handleClick} isSelected={selectedChild === '3'} 
                    question={items[6][1][4]}
                    answer={items[6][1][5]}
                />
                <Accordion
                    id="4" onClick={handleClick} isSelected={selectedChild === '4'} 
                    question={items[6][1][6]}
                    answer={items[6][1][7]}
                />
                <Accordion
                    id="5" onClick={handleClick} isSelected={selectedChild === '5'} 
                    question={items[6][1][8]}
                    answer={items[6][1][9]}
                />
            </div>

            <Testimonials 
                slideSpeed={5000}
                title={items[7][0][0]}
                copy1={items[7][1][0]}
                icon1={items[7][1][1]}
                name1={items[7][1][2]}
                copy2={items[7][2][0]}
                icon2={items[7][2][1]}
                name2={items[7][2][2]}
                copy3={items[7][3][0]}
                icon3={items[7][3][1]}
                name3={items[7][3][2]}
                copy4={items[7][4][0]}
                icon4={items[7][4][1]}
                name4={items[7][4][2]}
                copy5={items[7][5][0]}
                icon5={items[7][5][1]}
                name5={items[7][5][2]}
            />

            <SupportCta />
        </div>
    );
};

export default DownloadApp;