import React, { useEffect, useState } from 'react';
import HeadingTwo from "./HeadingTwo";

interface bannerProps {
    title: any,
    badge: string,
    background: string
}

function Banner({title, badge, background}: bannerProps) {
    const [inView, setInView] = useState(false);
    const headingRef = React.useRef<HTMLImageElement>(null);
    const [badgeInView, setBadgeInView] = useState(false);
    const badgeRef = React.useRef<HTMLImageElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (headingRef.current) {
          observer.observe(headingRef.current);
        }
        return () => {
          observer.disconnect();
        };
      }, [headingRef]);

      useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setBadgeInView(true);
            }
          });
        });
        if (badgeRef.current) {
          observer.observe(badgeRef.current);
        }
        return () => {
          observer.disconnect();
        };
      }, [badgeRef]);
  return (
    <div className='banner' style={{backgroundImage: `url(${background})`}}>
        <div ref={badgeRef} className={`badge ${badgeInView ? 'slide-down' : ''}`}>
            <h1>{badge}</h1>
        </div>
        <div className="headings">
            {/*{title && <div ref={headingRef}*/}
            {/*className={`reveal-heading ${inView ? 'slide-up' : ''}`} dangerouslySetInnerHTML={{ __html: title }} />}*/}
          <HeadingTwo title={title} />
        </div>
    </div>
  )
}

export default Banner