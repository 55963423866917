import React from 'react';

const UseHasCenter = (title: any) => {
  const [hasCenter, setHasCenter] = React.useState(false);

  React.useEffect(() => {
    let titleStyle:string[] = title.toString().match(/style\s*=\s*"([^"]*)"/g, '');

    //Check if has center style
    if(titleStyle !== null && titleStyle.length > 0){
      titleStyle.map((style)=>{
        if(style.includes('center')){
          setHasCenter(true);
        }
      })
    }
  }, [title]);

  return hasCenter;
};

export default UseHasCenter;