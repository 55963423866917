const AMDOCSAPIURL = 'https://productcatalog.amdocs-dbs.com/';
const TENANTID = '63580066dd64203b3e19d26b';
const VOICEBYOPID = '';
const DATABYOPID = '';
const TEXTBYOPID = '';

const fetchPackages = ( packageType: any ) => {
    return new Promise( ( resolve, reject ) => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'tenantid': TENANTID }
            };
            fetch( AMDOCSAPIURL + packageType, requestOptions)
                .then(response => response.json())
                .then(data => resolve(data) );
        }
        catch( error: any ) {
            reject(error);
        }
    });
}

const fetchInformationByString = ( informationString: any ) => {

    return new Promise( ( resolve, reject ) => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'tenantid': TENANTID }
            };
            fetch( informationString, requestOptions)
                .then(response => response.blob())
                .then( ( data: any ) => {
                    let responseImage: any = null;
                    if( data ) {
                        if ( data.type !== 'text/html') {

                            resolve (
                                new Promise( ( blobResolve, blobReject ) => {
                                    let reader = new FileReader();
                                    let objectUrl = URL.createObjectURL(data);
                                    reader.readAsDataURL(data);
                                    reader.onloadend = function() {
                                        var base64data = reader.result;
                                        // console.log(reader.result);
                                        blobResolve( base64data );
                                        // console.log('test');
                                    }
                                })
                            );
                            
                            // reader.onload = function(){ resolve(this.result); };
                            // reader.readAsDataURL(data);
                        }
                    } else {
                        resolve(responseImage);
                    }
                } );
        } catch (error: any ) {
            reject(error);
        }
    });

}

const fetchSingleBYOPOfferings = ( BYOPOfferringOptions: any ) => {

    return new Promise( ( offeringResolve, offeringReject ) => {
        try {
            
            const requestOptions = {
                method: 'GET',
                headers: { 'tenantid': TENANTID }
            }

            fetch( BYOPOfferringOptions, requestOptions)
            .then(response => response.json())
                .then( ( productOfferingsData: any ) => {
                    offeringResolve( productOfferingsData );
                })
                .catch( (error: any) => {
                    offeringReject( error );
                });


        } catch (error) {
            offeringReject( error );
        }
    });

}

export { fetchPackages, fetchInformationByString, fetchSingleBYOPOfferings };