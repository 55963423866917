
const setCookie = (cname: any, cvalue: any, exdays: any) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;";
}
  
const getCookie = (cname: any ) => {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
}

const checkCookie = (cname: string ) => {
    let user = getCookie(cname);
    if (user != "") {
        // alert("Welcome again " + user);
        return user;
    } else {
        return null;
    }
}


const test = () => {
    return 'Cookie Helper Working...';
}


export { setCookie, getCookie, checkCookie, test };