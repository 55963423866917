import React, { useEffect } from 'react';
import {useLoaderData} from "react-router-dom";
import ErrorBanner from '../components/ErrorBanner';
import { PageData } from "../types/PageData";
import { Helmet } from 'react-helmet-async';
import Navigation from '../components/Navigation';

const Error404 = () => {
    // Page Data From Router
    const PageData = useLoaderData() as PageData;
    const items = PageData.content.no_short_codes;
    const aiButtonHref = /.*href="([^"]*)".*title="([^"]*)".*/;
    const yoast:any = PageData.yoast_head_json


  useEffect(() => {
        document.body.style.scrollBehavior = "auto";
        document.body.scrollTop = 0;
        document.documentElement.style.scrollBehavior = "auto";
        document.documentElement.scrollTop = 0;
    }, []);

  return (
      <div>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : "404 - Melon Mobile"}</title>
            <meta charSet="utf-8" />
            <meta name="description" content={yoast !== undefined ? yoast.description : "We believe the current way you’re forced to mobile is broken. With big networks offering things that really only benefit themselves. Long-term contracts, outdated plans, complicated experiences, and a one-size-fits-all approach have left most of us feeling rather bitter."}/>
            <meta property="og:description" content={yoast !== undefined ? yoast.og_description: "We believe the current way you’re forced to mobile is broken. With big networks offering things that really only benefit themselves. Long-term contracts, outdated plans, complicated experiences, and a one-size-fits-all approach have left most of us feeling rather bitter."} />
            <meta property="og:locale" content={yoast !== undefined ? yoast.og_locale : "en_US"} />
            <meta property="og:type" content={yoast !== undefined ? yoast.og_type : "article"} />
            <meta property="og:title" content={yoast !== undefined ? yoast.og_title : "404 - Melon Mobile"} />
            <meta property="og:site_name" content={yoast !== undefined ? yoast.og_site_name : "Melon Mobile"} />
            <meta property="og:url" content={yoast !== undefined ? yoast.og_url : `${process.env.REACT_APP_SECRET_MELON_MOBILE_CONTENT}404-2/`} />
        </Helmet>
        <Navigation />
        <ErrorBanner 
            title={items[0][0][0]}
            subtitle={items[0][1][0]}
            link={items[0][1][1].replace(aiButtonHref, '$1')}
            linkText={items[0][1][1].replace(aiButtonHref, '$2')}
            link2={items[0][2][0].replace(aiButtonHref, '$1')}
            linkText2={items[0][2][0].replace(aiButtonHref, '$2')}
            image={items[0][3][0]}
        />
      </div>
  );
};

export default Error404;