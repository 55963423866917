import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import HeadingTwo from './HeadingTwo';
import Button from './Button';
import lazyLoadImage from '../helpers/LazyLoadImage';

interface dealsProps {
    title: string,
    image1: string,
    image2: string,
    link: string,
    linkText: string
}

function LatestDeals({...props}: dealsProps) {
    const [inView, setInView] = useState(false);
    const imgRef = React.useRef<HTMLImageElement>(null);
    
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (imgRef.current) {
          observer.observe(imgRef.current);
        }
        return () => {
          observer.disconnect();
        };
      }, [imgRef]);
  return (
    <div className='latest-deals ai-py-4'>
        <div className="container">
            {props.title && <HeadingTwo title={props.title} />}
            <div className="row">
                <div className="col-12 col-md-6 mb-3 mb-md-0">
                    {props.image1 && <div ref={imgRef}
                    className={`image-reveal main-image ${inView ? 'slide-up' : ''}`} dangerouslySetInnerHTML={{ __html: lazyLoadImage(props.image1) }} />}
                </div>
                <div className="col-12 col-md-6">
                    {props.image2 && <div ref={imgRef}
                    className={`image-reveal main-image ${inView ? 'slide-up' : ''}`} dangerouslySetInnerHTML={{ __html: lazyLoadImage(props.image2) }} />}
                </div>
                <div className="ai-pt-4 text-center">
                    <Button type='button' className={'btn btn-primary'} linkText={props.linkText} link={props.link} />
                </div>
            </div>
        </div>
    </div>
  )
}

export default LatestDeals