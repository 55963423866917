import React, { useEffect, useState } from 'react';
import { PageData } from "../types/PageData";
import {useLoaderData} from "react-router-dom";
import Banner from '../components/Banner';
import MelonSteps from '../components/MelonSteps';
import Accordion from '../components/Accordion';
import DownloadCta from '../components/DownloadCta';
import { Helmet } from 'react-helmet-async';

import background from '../assets/images/Melon-Mobile-Banner-Background.jpg';
import image1 from '../assets/images/Melon-Mobile-Your-Plan-Made-Your-Way-CTA.png';
import HeadingTwo from '../components/HeadingTwo';
import Navigation from '../components/Navigation';
import Button from '../components/Button';

const Deals = () => {
    const [inView, setInView] = useState(false);
    const imgRef = React.useRef<HTMLImageElement>(null);
    const [inView2, setInView2] = useState(false);
    const imgRef2 = React.useRef<HTMLImageElement>(null);
    const [selectedChild, setSelectedChild] = useState<string | null>(null);

      const handleClick = (childId: string) => {
        setSelectedChild(childId);
    };
    // Page Data From Router
    const PageData = useLoaderData() as PageData
    const items = PageData.content.no_short_codes;
    const aiButtonHref = /.*href="([^"]*)".*title="([^"]*)".*/;
    const yoast:any = PageData.yoast_head_json


  const ctaData:any = {
        title:'It’s your plan made your way.',
        copy: '<p>The first step to digital freedom takes less than a minute. Download the Melon App now.</p>',
        image: image1
    }

    useEffect(() => {
        document.body.style.scrollBehavior = "auto";
        document.body.scrollTop = 0;
        document.documentElement.style.scrollBehavior = "auto";
        document.documentElement.scrollTop = 0;
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (imgRef.current) {
          observer.observe(imgRef.current);
        }
        return () => {
          observer.disconnect();
        };
    }, [imgRef]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView2(true);
            }
          });
        });
        if (imgRef2.current) {
          observer.observe(imgRef2.current);
        }
        return () => {
          observer.disconnect();
        };
    }, [imgRef2]);

    console.log(items[9]);

  return (
    <div className='deals'>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : "Melon Deals - Melon Mobile"}</title>
            <meta charSet="utf-8" />
            <meta name="description" content={yoast !== undefined ? yoast.description : "No-one walks into a grocery store and picks a melon straight off the shelf. No, they tap it a few times, place their ear on the side (as if listening to a melon really works) and then choose the one that just feels right. Well, that’s what we’re letting you do. Download the Melon App, activate your Melon SIM and we’ll give you 1GB of data, 100 minutes voice and 100 texts so you can taste test the Melon."}/>
            <meta property="og:description" content={yoast !== undefined ? yoast.og_description: "No-one walks into a grocery store and picks a melon straight off the shelf. No, they tap it a few times, place their ear on the side (as if listening to a melon really works) and then choose the one that just feels right. Well, that’s what we’re letting you do. Download the Melon App, activate your Melon SIM and we’ll give you 1GB of data, 100 minutes voice and 100 texts so you can taste test the Melon."} />
            <meta property="og:locale" content={yoast !== undefined ? yoast.og_locale : "en_US"} />
            <meta property="og:type" content={yoast !== undefined ? yoast.og_type : "article"} />
            <meta property="og:title" content={yoast !== undefined ? yoast.og_title : "Melon Deals - Melon Mobile"} />
            <meta property="og:site_name" content={yoast !== undefined ? yoast.og_site_name : "Melon Mobile"} />
            <meta property="og:image" content={yoast !== undefined ? yoast.og_image[0].url: `${process.env.REACT_APP_SECRET_MELON_MOBILE_CONTENT}wp-content/uploads/2023/01/Melon-Mobile-Deals-Step-1.svg`} />
            <meta property="og:url" content={yoast !== undefined ? yoast.og_url : `${process.env.REACT_APP_SECRET_MELON_MOBILE_CONTENT}deals/`} />
        </Helmet>
        <Navigation />
        {/* <Banner 
            badge={PageData.title.rendered} 
            title={items[0][0][0]+items[0][0][1]} 
            background={background} 
        /> */}

        <div className="ai-promo-image-container container">
            <div dangerouslySetInnerHTML={{ __html: items[7][0]}} />
        </div>

        <div className="ai-flip-side-steps-container">
            <div className="container ai-pt-7 ai-pb-5">
                <div className="row ai-mb-3">
                    <div className="col-12 text-center mx-auto">
                        <p>{items[8][0]}</p>
                        <div className="promo-code-container">
                            <p dangerouslySetInnerHTML={{__html: items[8][1][0]}}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        items[8].map((step:any, index:number)=>{
                            if(index > 1){
                                return(
                                    <div key={index} className="col-12 col-md-6 col-lg-3 d-flex align-items-center flex-column">
                                        <div dangerouslySetInnerHTML={{ __html: step[0]}} />
                                        <p className='text-center' dangerouslySetInnerHTML={{ __html: step[1]}} />
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </div>

        <div className="ai-shop-now-container text-center ai-pb-7">
            <div className="container">
                <HeadingTwo title={items[9][0][0]} />
                <p dangerouslySetInnerHTML={{ __html: items[9][1][2].paragraphs}} />
                <div className="row">
                    {
                        items[9].map(((image:any, index:number)=>{
                            if(index > 0 && index < items[9].length){
                                if(index == 2){
                                    return(
                                        <div className="col-12 col-md-3">
                                            <a target='_blank'>
                                                <div dangerouslySetInnerHTML={{ __html: image[0]}} />
                                            </a>
                                        </div>
                                    )
                                } else{
                                    return(
                                        <div className="col-12 col-md-3">
                                            <a href={image[1]} target='_blank'>
                                                <div dangerouslySetInnerHTML={{ __html: image[0]}} />
                                            </a>
                                        </div>
                                    )
                                }
                            }
                        }))
                    }
                </div>
            </div>
            <a href='/terms-and-conditions' className='tnc-paragraph' dangerouslySetInnerHTML={{ __html: items[9][5].paragraphs}}/>
        </div>

        <div className="ai-promo-image-container container ai-mb-7">
            <div dangerouslySetInnerHTML={{ __html: items[10][0]}} />
        </div>

        <div className="ai-why-switch-container">
            <div className="container ai-pb-7">
                <div className="row ai-mb-3">
                    <div className="col-12 text-center mx-auto">
                        <HeadingTwo title={items[11][0]} />
                        {/* <h2>{items[11][0]}</h2> */}
                        <div className="promo-code-container">
                            <p className='ai-mb-2' dangerouslySetInnerHTML={{__html: items[11][1][0]}}/>
                            <p dangerouslySetInnerHTML={{__html: items[11][1][1] + items[11][1][2]}}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        items[11].map((step:any, index:number)=>{
                            if(index > 1){
                                return(
                                    <div key={index} className="col-12 col-md-6 col-lg-3 d-flex align-items-center flex-column">
                                        <div dangerouslySetInnerHTML={{ __html: step[0]}} />
                                        <p className='text-center' dangerouslySetInnerHTML={{ __html: step[1]}} />
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </div>

        <div className="ai-large-image-banner container">
            <div className="row">
                {/* <div className="col-12 col-md-10 col-lg-8 mx-auto">
                    {items[1][0][0] === '' ? null : <div className='normal-weight-heading'><HeadingTwo title={items[1][0][0]} /></div>}
                </div> */}
                <div className="col-12">
                    {items[1][1][0] === '' ? null : <div ref={imgRef}
                    className={`image-reveal ${inView ? 'slide-up' : ''}`} dangerouslySetInnerHTML={{ __html: items[1][1][0] }} />}
                </div>
                <div className="col-12 col-md-10 col-lg-11 mx-auto text-center">
                    {items[1][1][1] === '' ? null : <p>{items[1][1][1]}</p>}
                </div>
            </div>
        </div>

        <MelonSteps 
            title={items[2][0][0]} 
            introSubtitle='' 
            image1={items[2][1][0]} 
            image2={items[2][3][0]} 
            image3={items[2][5][0]} 
            copy1={items[2][2][0]+items[2][2][1]} 
            copy2={items[2][4][0]+items[2][4][1]} 
            copy3={items[2][6][0]+items[2][6][1]} 
            link={items[2][7][0].replace(aiButtonHref, '$1')}
            linkType={'external'}
            linkText={items[2][7][0].replace(aiButtonHref, '$2')} 
        />

        <div className="ai-large-image-banner container">
            <div className="row">
                <div className="col-12 col-md-10 col-lg-8 mx-auto">
                    {items[6][0][0] === '' ? null : <div className='normal-weight-heading'><HeadingTwo title={items[6][0][0]} /></div>}
                </div>
                <div className="col-12">
                    {items[6][1][0] === '' ? null : <div ref={imgRef2}
                    className={`image-reveal ${inView2 ? 'slide-up' : ''}`} dangerouslySetInnerHTML={{ __html: items[6][1][0] }} />}
                </div>
                <div className="col-12 col-md-10 col-lg-11 mx-auto text-center">
                    {items[6][1][1] === '' ? null : <p>{items[6][1][1]}</p>}
                </div>
            </div>
        </div>
        {/* <div className="bg-light">
            <div className="ai-large-image-banner container">
                <div className="row">
                    <div className="col-12 col-md-10 col-lg-9 mx-auto">
                        {items[3][0][0] === '' ? null : <div className='normal-weight-heading'><HeadingTwo title={items[3][0][0]} /></div>}
                    </div>
                    <div className="col-12">
                        {items[3][1][0] === '' ? null : <div ref={imgRef2}
                        className={`image-reveal ${inView2 ? 'slide-up' : ''}`} dangerouslySetInnerHTML={{ __html: items[3][1][0] }} />}
                    </div>
                    <div className="col-12 col-md-10 col-lg-9 mx-auto text-center">
                        {items[3][1][1] === '' ? null : <p>{items[3][1][1]}</p>}
                    </div>
                </div>
            </div>

            <div className="container ai-column-text">
                {items[4][0][0] === '' ? null : <HeadingTwo title={items[4][0][0]} />}
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-3 text-center">
                        {items[4][1][0] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[2][1][0]}} />}
                        {items[4][1][1] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[4][1][1]}} />}
                        {items[4][1][2] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[4][1][2]}} />}
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 text-center">
                        {items[4][2][0] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[4][2][0]}} />}
                        {items[4][2][1] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[4][2][1]}} />}
                        {items[4][2][2] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[4][2][2]}} />}
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 text-center">
                        {items[4][3][0] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[4][3][0]}} />}
                        {items[4][3][1] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[4][3][1]}} />}
                        {items[4][3][2] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[4][3][2]}} />}
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 text-center">
                        {items[4][4][0] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[4][4][0]}} />}
                        {items[4][4][1] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[4][4][1]}} />}
                        {items[4][4][2] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[4][4][2]}} />}
                    </div>
                </div>
            </div>
        </div> */}

        <div className="container accordion-wrapper">
            {items[5][0][0] === '' ? null : <HeadingTwo title={items[5][0][0]} />}

            <Accordion 
                id="1" onClick={handleClick} isSelected={selectedChild === '1'}
                question={items[5][1][0]}
                answer={items[5][1][1]}
            />
            <Accordion 
                id="2" onClick={handleClick} isSelected={selectedChild === '2'}
                question={items[5][1][2]}
                answer={items[5][1][3]}
            />
            <Accordion 
                id="3" onClick={handleClick} isSelected={selectedChild === '3'}
                question={items[5][1][4]}
                answer={items[5][1][5]}
            />
            <Accordion
                id="4" onClick={handleClick} isSelected={selectedChild === '4'} 
                question={items[5][1][6]}
                answer={items[5][1][7]}
            />
        </div>

        <DownloadCta 
            title={ctaData.title} 
            copy={ctaData.copy} 
            image={ctaData.image} 
        />
    </div>
  );
};

export default Deals;