import React from 'react';
import {motion} from "framer-motion";

const HeadingAnimation = (Title:any, inView: any) => {
  //SAVE ORIGINAL
  const OriginalTitle = Title;
  let animatedTitle;
  let animatedTitleArray:any[] = []
  let strongTitleIndex;
  let strongTitle;
  let strongTitleArray:any[]=[];


  //Remove H2
  animatedTitle = Title.toString().replace(/<(?!\/?b>|\/?strong>)[^>]+>/g, '');

  //split title into spans keeping strong tags in place
  animatedTitleArray = animatedTitle.split(/(<strong>.*?<\/strong>)|\s/g)

  //Remove undefined and empty strings from array
  animatedTitleArray = animatedTitleArray.filter((word: string) => word !== undefined && word !== '')

   //Index with strong tag
  //  strongTitleIndex = animatedTitleArray.indexOf
  animatedTitleArray.forEach((str,i)=>{
    if(str.match(/<strong>/g)){
      strongTitle = str;
      animatedTitleArray.splice(i,1);
      strongTitle = strongTitle.replace('<strong>', '').replace('</strong>', '')
      strongTitleArray = strongTitle.split(" ");
      strongTitleArray = strongTitleArray.map((word:string)=>{
        return `<strong>${word}</strong>`
      })

      if (i == 0){
        animatedTitleArray = strongTitleArray.concat(animatedTitleArray)
      } else{
          animatedTitleArray = animatedTitleArray.concat(strongTitleArray)
      }
    }
  })

  //Add Empty spaces after words
  animatedTitleArray = animatedTitleArray.map((word: string) => word + '&nbsp;');

  return (
      animatedTitleArray.map((word, index)=>{
        return (
            <span className={`ai-reveal-box`} key={index}>
              <motion.span
                  initial={{opacity: 0, y: '-100%'}}
                  animate={inView && {opacity: 1, y: '0'}}
                  transition={{delay: index * 0.1, duration: 0.5}}
                  className={'ai-animated-word'} dangerouslySetInnerHTML={{__html: word}}>
              </motion.span>
            </span>
            )
      })
  );
};

export default HeadingAnimation;