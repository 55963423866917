import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Button from './Button'
import HeadingTwo from "./HeadingTwo";
import Lottie from 'react-lottie';
import { useInView } from 'react-intersection-observer';

//Animations
import availableInSA from '../assets/lottie/melon-mobile-available-in-south-africa-animation.json';
import stillSearching from '../assets/lottie/melon-mobile-still-searching-animation.json';
import downloadApp from '../assets/lottie/melon-mobile-download-app-animation.json';
import buildYourOwnPlan from '../assets/lottie/melon-mobile-build-your-own-plan-animation.json';
import activateYourSim from '../assets/lottie/melon-mobile-activate-your-sim-animation.json';
import lazyLoadImage from '../helpers/LazyLoadImage';

interface contentProps {
    layout: string,
    image: string,
    numbers: string,
    title: string,
    copy: string,
    list: string,
    link: string,
    linkText: string,
    logos?: any,
    animation?: boolean,
    animationName?: string
}

function ContentImage({...props}: contentProps) {
    const [inView, setInView] = useState(false);
    const imgRef = React.useRef<HTMLImageElement>(null);

    //Lottie Animations
    const lottieRef = React.useRef<HTMLImageElement>(null);
    const [ref, inView3] = useInView({
        triggerOnce: true,
        rootMargin: '200px'
      });

      useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (lottieRef.current) {
          observer.observe(lottieRef.current);
        }
        return () => {
          observer.disconnect();
        };
    }, [lottieRef]);

    //Available in South Africa animation
    const availableInSAOptions = {
        loop: true,
        autoplay: true, 
        animationData: availableInSA,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    //Still searching animation
    const stillSearchingOptions = {
      loop: true,
      autoplay: true, 
      animationData: stillSearching,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
  };

  //Download app
  const downloadAppOptions = {
    loop: true,
    autoplay: true, 
    animationData: downloadApp,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
};

//Build your own plan
const buildYourOwnPlanOptions = {
    loop: true,
    autoplay: true, 
    animationData: buildYourOwnPlan,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
};

//Activate Your sim
const activateYourSimOptions = {
    loop: true,
    autoplay: true, 
    animationData: activateYourSim,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
};

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (imgRef.current) {
          observer.observe(imgRef.current);
        }
        return () => {
          observer.disconnect();
        };
      }, [imgRef]);

      const [inView2, setInView2] = useState(false);
      const headingRef = React.useRef<HTMLImageElement>(null);
      
      useEffect(() => {
          const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                setInView2(true);
              }
            });
          });
          if (headingRef.current) {
            observer.observe(headingRef.current);
          }
          return () => {
            observer.disconnect();
          };
        }, [headingRef]);
  return (
    <div className='container content-image'>
            {props.layout === 'image left' ? 
                <div className='row image-left'>
                    <div className="col-12 col-md-6 image">
                      {props.animation &&
                        <div ref={lottieRef}>
                          <div className='d-block lottie-image' ref={ref}>
                            {
                              props.animationName == 'available-in-sa' && <Lottie options={availableInSAOptions} height={380} width={380} isStopped={!inView} />
                            } 
                            {
                              props.animationName == 'still-searching' && <Lottie options={stillSearchingOptions} height={380} width={380} isStopped={!inView} />
                            } 
                            {
                              props.animationName == 'download-app' && <Lottie options={downloadAppOptions} height={500} width={500} isStopped={!inView} />
                            }
                            {
                              props.animationName == 'activate-sim' && <Lottie options={activateYourSimOptions} height={500} width={500} isStopped={!inView} />
                            }
                          </div>
                        </div>
                      }
                        {props.animation ? null : <div ref={imgRef}
                        className={`image-reveal ${inView ? 'slide-up' : ''}`} dangerouslySetInnerHTML={{ __html: lazyLoadImage(props.image) }} />}
                    </div>
                    <div className="col-12 col-md-6 text">
                        {props.numbers === '' ? null : <h5>{props.numbers}</h5>}
                        {props.title === '' ? null : <div ref={headingRef}
                        className={`reveal-heading ${inView2 ? 'slide-up' : ''}`}><HeadingTwo title={props.title}/></div>}
                        {props.copy === '' ? null : <p>{props.copy}</p>}
                        {props.list === '' ? null : <p>{props.list}</p>}
                        {props.logos === '' ? null : <div className='stores' dangerouslySetInnerHTML={{ __html: props.logos}} />}
                        {props.linkText === '' ? null :
                            <Button type='button' className='btn btn-primary' linkText={props.linkText} link={props.link} />
                        } 
                    </div>
                </div> 
            : 
                <div className='row image-right'>
                    <div className="col-12 col-md-6 text">
                        {props.numbers === '' ? null : <h5>{props.numbers}</h5>}
                        {props.title === '' ? null : <div ref={headingRef}
                        className={`reveal-heading ${inView2 ? 'slide-up' : ''}`}><HeadingTwo title={props.title}/></div>}
                        {props.copy === '' ? null : <p>{props.copy}</p>}
                        {props.list === '' ? null : <div dangerouslySetInnerHTML={{ __html: props.list }} />}
                        {props.linkText === '' ? null :
                            <Button type='button' className='btn btn-primary' linkText={props.linkText} linkType={'external'} link={props.link} />
                        } 
                    </div>
                    <div className="col-12 col-md-6 image">
                      {props.animation &&
                          <div ref={lottieRef}>
                            <div className='d-block lottie-image' ref={ref}>
                              {
                                props.animationName == 'available-in-sa' && <Lottie options={availableInSAOptions} height={380} width={380} isStopped={!inView} />
                              } 
                              {
                                props.animationName == 'build-own-plan' && <Lottie options={buildYourOwnPlanOptions} height={500} width={500} isStopped={!inView} />
                              } 
                            </div>
                          </div>
                      }
                        {props.animation ? null : <div ref={imgRef}
                        className={`image-reveal ${inView ? 'slide-up' : ''}`} dangerouslySetInnerHTML={{ __html: lazyLoadImage(props.image) }} />}
                    </div>
                </div>
            }
    </div>
  )
}

export default ContentImage