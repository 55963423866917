import React, {useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';
import Button from './Button';
import Lottie from 'react-lottie';
import { useInView } from 'react-intersection-observer';

//Animations
import chatToUs from '../assets/lottie/melon-mobile-chat-to-us-animation.json';
import faqHelp from '../assets/lottie/melon-mobile-faq-pop.-animationjson.json';

//Images
import chat from '../assets/images/icons/Melon-Mobile-Chat-To-Us-CTA.svg';
import help from '../assets/images/icons/Melon-Mobile-Help-Center-CTA.svg';

function SupportCta() {
    const [inView, setInView] = useState(false);
    const headingRef = React.useRef<HTMLImageElement>(null);
    const lottieRef = React.useRef<HTMLImageElement>(null);
    const [ref, inView2] = useInView({
        triggerOnce: true,
        rootMargin: '200px'
      });

      useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (lottieRef.current) {
          observer.observe(lottieRef.current);
        }
        return () => {
          observer.disconnect();
        };
    }, [lottieRef]);

    //Chat to Us Options
    const chatToUsOptions = {
        loop: true,
        autoplay: true, 
        animationData: chatToUs,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    //FAQ Help 
    const faqHelpOptions = {
        loop: true,
        autoplay: true, 
        animationData: faqHelp,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (headingRef.current) {
          observer.observe(headingRef.current);
        }
        return () => {
          observer.disconnect();
        };
      }, [headingRef]);

    const supportCta: any = [
        {
            id: 1,
            image: help,
            title: 'Still need help? We’re always here.',
            copy: 'Our self help platform is here to get you out of trouble and into freedom.',
            link: '/help-center',
            linkText: 'Explore our help centre',
            animationOptions: faqHelpOptions
        },
        {
            id: 2,
            image: chat,
            title: 'Still looking for answers?',
            copy: 'Chat to one of our online assistants who will do their best to help you.',
            link: '/contact',
            linkText: 'Chat to us',
            animationOptions: chatToUsOptions
        }
    ]
  return (
    <div className='support-cta'>
        <div className="container">
            <div className="row py-0 justify-content-around">
                {supportCta.map((item:any) => (
                <div key={item.id} className="col-12 col-md-5 col-lg-4">
                    <div className="image text-center">
                      <div ref={lottieRef}>
                            {/* {item.image && <div className='d-lg-none' dangerouslySetInnerHTML={{ __html: item.image }} />} */}
                            <div className='d-block lottie-image' ref={ref}>
                                <Lottie options={item.animationOptions} height={200} width={200} isStopped={!inView} />
                            </div>
                      </div>
                    </div>
                    <div className="content text-center">
                        <div ref={headingRef}
                        className={`reveal-heading ${inView ? 'slide-up' : ''}`}><h4>{item.title}</h4></div>
                        <p>{item.copy}</p>
                        <Button type='button' linkText={item.linkText} link={item.link} className={'btn btn-primary'} />
                    </div>
                </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default SupportCta