import React, { useEffect, useRef, useState } from 'react';
import OwnPlan from './OwnPlan';
import PackagesTiles from './PackagesTiles';
import Button from './Button';
import HeadingTwo from "./HeadingTwo";

function PackagesTabs() {
    const [inView, setInView] = useState(false);
    const headingRef = React.useRef<HTMLImageElement>(null);
    const [showingTab, setShowingTab] = useState('sliders');
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (headingRef.current) {
          observer.observe(headingRef.current);
        }
        return () => {
          observer.disconnect();
        };
    }, [headingRef]);

  return (
    <div className='package-tabs'>
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-10 col-lg-7 mx-auto text-center pb-5">
                    <div ref={headingRef}
                    className={`reveal-heading ${inView ? 'slide-up' : ''}`}><HeadingTwo title={'Mzansi’s first fully customisable mobile plan'}/></div>
                    <p>Melon gives you the freedom to go your own way. You can build your own plan or select a plan that works for you.</p>
                </div>
            </div>
            <div className="tab-buttons">
                <button className={showingTab === 'sliders' ? 'sliders active' : 'sliders'} onClick={() => setShowingTab('sliders')}>Build Your Own Plan</button>
                <button className={showingTab === 'tiles' ? 'tiles active' : 'tiles'} onClick={() => setShowingTab('tiles')}>Recommended plans</button>
            </div>
        </div>
        <div className={showingTab === 'sliders' ? 'tab showing' : 'tab'}>
            <OwnPlan />
        </div>
        <div className={showingTab === 'tiles' ? 'tab showing' : 'tab'}>
            <PackagesTiles />
        </div>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="all-plan-link text-center">
                        <Button 
                            type='button'
                            className='btn btn-primary'
                            link='/packages'
                            linkText='View all our plans'
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PackagesTabs