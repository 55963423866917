import React, { useEffect, useState } from 'react';
import {useLoaderData} from "react-router-dom";
import { PageData } from "../types/PageData";
import Banner from '../components/Banner';
import ContentImage from '../components/ContentImage';
import DownloadCta from '../components/DownloadCta';
import Accordion from '../components/Accordion';

import image1 from '../assets/images/Melon-Mobile-Your-Plan-Made-Your-Way-CTA.png';
import background from '../assets/images/Melon-Mobile-Banner-Background.jpg';
import SupportCta from '../components/SupportCta';
import { Helmet } from 'react-helmet-async';
import HeadingTwo from '../components/HeadingTwo';
import HeadingHardcoded from '../components/HeadingHardcoded';
import Navigation from '../components/Navigation';

const GetASimPromo = () => {
    const [inView, setInView] = useState(false);
    const imgRef = React.useRef<HTMLImageElement>(null);
    const [selectedChild, setSelectedChild] = useState<string | null>(null);

      const handleClick = (childId: string) => {
        setSelectedChild(childId);
    };
    // Page Data From Router
    const PageData = useLoaderData() as PageData;
    const items = PageData.content.no_short_codes
    const yoast:any = PageData.yoast_head_json


  const ctaData:any = {
      title:'It’s your plan made your way.',
      copy: '<p>The first step to digital freedom takes less than a minute. Download the Melon App now.</p>',
      image: image1
    }

    useEffect(() => {
        document.body.style.scrollBehavior = "auto";
        document.body.scrollTop = 0;
        document.documentElement.style.scrollBehavior = "auto";
        document.documentElement.scrollTop = 0;
    }, []);

     useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                setInView(true);
                } 
            });
        });
        if (imgRef.current) {
            observer.observe(imgRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, [imgRef]);

  return (
    <div className='free-sim-promo'>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : "The melon sim - Melon Mobile"}</title>
            <meta charSet="utf-8" />
            <meta name="description" content={yoast !== undefined ? yoast.description : "Try Before You Buy, Build Your Own Plan, change your monthly plan and top-up. It’s the freedom of mobile in one little package."}/>
            <meta property="og:description" content={yoast !== undefined ? yoast.og_description: "Try Before You Buy, Build Your Own Plan, change your monthly plan and top-up. It’s the freedom of mobile in one little package."} />
            <meta property="og:locale" content={yoast !== undefined ? yoast.og_locale : "en_US"} />
            <meta property="og:type" content={yoast !== undefined ? yoast.og_type : "article"} />
            <meta property="og:title" content={yoast !== undefined ? yoast.og_title : "The melon sim - Melon Mobile"} />
            <meta property="og:site_name" content={yoast !== undefined ? yoast.og_site_name : "Melon Mobile"} />
            <meta property="og:url" content={yoast !== undefined ? yoast.og_url : `${process.env.REACT_APP_SECRET_MELON_MOBILE_CONTENT}get-a-sim-promo/`} />
            <meta property="og:image" content={yoast !== undefined ? yoast.og_image[0].url:`${process.env.REACT_APP_SECRET_MELON_MOBILE_CONTENT}wp-content/uploads/2023/01/WoolWorths.svg`} />
        </Helmet>
        <Navigation />
        <Banner 
            badge={PageData.title.rendered} 
            title={items[0][0][0]} 
            background={background} 
        />

        <ContentImage
            layout='image right'
            image={items[1][0]}
            title={items[1][1]}
            copy={items[1][2]}
            link='https://melon.selfcare.amdocs-dbs.com/login'
            linkText='Order Now'
            numbers=''
            list=''
        />

        {/* <div className="centered-text container">
            <div className="row">
                <div className="col-12 col-md-10 mx-auto">
                    {items[2][0][0] === '' ? null : <HeadingTwo title={items[2][0][0]} />} 
                    {items[2][1][0] === '' ? null : <div dangerouslySetInnerHTML={{ __html: items[2][1][0] }} />} 
                </div>
            </div>
        </div> */}

        <div className="with-icon with-store-logo">
            <ContentImage
                layout='image left'
                image={items[4][0]}
                title={items[4][2]}
                copy={items[4][3]}
                link=''
                linkText=''
                numbers=''
                list=''
                animation = {true}
                animationName = 'activate-sim'
            />
        </div>

        {/* <div className="container">
            <div className="divider">
                <div className="line"></div> <span>OR</span> <div className="line"></div>
            </div>
        </div>

        <div className='container content-image with-icon'>
            <div className='row image-right'>
                <div className="col-12 col-md-6 text">
                    {items[3][1] === '' ? null : <h5>{items[3][1]}</h5>}
                    {items[3][2] === '' ? null : <HeadingHardcoded title={items[3][2]} />}
                    {items[3][3] === '' ? null : <p>{items[3][3]}</p>}
                    {items[3][4][0] === '' ? null : <div className='stores' dangerouslySetInnerHTML={{ __html: items[3][4][0] }} />}
                </div>
                <div className="col-12 col-md-6 image">
                    {items[3][0] === '' ? null : <div ref={imgRef}
                    className={`image-reveal ${inView ? 'slide-up' : ''}`} dangerouslySetInnerHTML={{ __html: items[3][0] }} />}
                </div>
            </div>
        </div> */}

        <DownloadCta 
            title={ctaData.title} 
            copy={ctaData.copy} 
            image={ctaData.image} 
        />

        <div className="container accordion-wrapper">
            {items[5][0][0] === '' ? null : <HeadingTwo title={items[5][0][0]} />}

            <Accordion 
                id="1" onClick={handleClick} isSelected={selectedChild === '1'}
                question={items[5][1][0]}
                answer={items[5][1][1]}
            />
            <Accordion 
                id="2" onClick={handleClick} isSelected={selectedChild === '2'}
                question={items[5][1][2]}
                answer={items[5][1][3]}
            />
            <Accordion
                id="3" onClick={handleClick} isSelected={selectedChild === '3'} 
                question={items[5][1][4]}
                answer={items[5][1][5]}
            />
            <Accordion
                id="4" onClick={handleClick} isSelected={selectedChild === '4'} 
                question={items[5][1][6]}
                answer={items[5][1][7]}
            />
        </div>

        <SupportCta />
    </div>
  );
};

export default GetASimPromo;