import React, {useState, useEffect} from 'react';
import Footer from './Footer';
import PromoSticker from './PromoSticker';
import SubscribeModal from './SubscribeModal';
import {Outlet} from 'react-router-dom';

import Cookies from './Cookies';

function Header() {
    const [scrolled, setScrolled] = useState(false);
    // const loaderRef = useRef<any>()

    // const location = useLocation()
    // let currentLocation = '/'

    // useEffect(() => {
    //   loaderRef.current.classList.add('transitioning');
    //
    // })
    //
    // useEffect(()=>{
    //   setTimeout(() => {
    //     loaderRef.current.classList.remove('transitioning');
    //   }, 1000)
    //
    // }, [location])


    useEffect(() => {
        const handleScroll = () => {
          if (window.pageYOffset > 43 && !scrolled) {
            setScrolled(true);
          } else if (window.pageYOffset <= 43 && scrolled) {
            setScrolled(false);
          }
        };
        
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, [scrolled]);
  return (
    <div>
      {/*<div className={'ai-page-transition-container transitioning'} ref={loaderRef}></div>*/}
        <PromoSticker />
        {/* <SubscribeModal /> */}

        <main className={scrolled ? 'scrolled' : ''}>
            <Outlet />
        </main>
        <Footer />
        <Cookies />
    </div>
  )
}

export default Header