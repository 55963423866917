import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Lottie from 'react-lottie';
import { useInView } from 'react-intersection-observer';

// Other components
import InlineLoading from "./InlineLoading";

//Animations
import studenPlan from '../assets/lottie/melon-mobile-student-melon-animation.json';
import familyPlan from '../assets/lottie/melon-mobile-family-melon-animation.json';
import influencerPlan from '../assets/lottie/melon-mobile-influencer-melon-animation.json';
import gamerPlan from '../assets/lottie/melon-mobile-gamer-melon-animation.json';
import travellerPlan from '../assets/lottie/melon-mobile-traveller-melon.-animationjson.json';
import businessPlan from '../assets/lottie/melon-mobile-business-melon-animation.json';

// Custom functions
import { fetchPackages, fetchInformationByString } from '../functions/fetchDataAPI';
import { convertTextToLinkFriendly, convertMinutes, createPlannedPackageValues, createPackagePrice, sort_order_by_price } from '../helpers/generalFormating';

//Icons
import icon1 from '../assets/images/packages/Melon-Mobile-Try-Before-You-Buy-2.svg';
import icon2 from '../assets/images/packages/Melon-Mobile-Student-Plan.svg';
import icon3 from '../assets/images/packages/Melon-Mobile-Family-Plan.svg';
import icon4 from '../assets/images/packages/Melon-Mobile-Influencer-Plan.svg';
import icon5 from '../assets/images/packages/Melon-Mobile-Packages-Gamer-Melon.svg';
import icon6 from '../assets/images/packages/Melon-Mobile-Packages-Business-Melon.svg';
import icon7 from '../assets/images/packages/Melon-Mobile-Packages-Traveller-Melon.svg';
import dataIcon from '../assets/images/packages/Melon-Mobile-Data-Dark-Icon.svg';
import callIcon from '../assets/images/packages/Melon-Mobile-Call-Dark-Icon.svg';
import smsIcon from '../assets/images/packages/Melon-Mobile-Text-Dark-Icon.svg';
import dataIconWhite from '../assets/images/packages/Melon-Mobile-Data-light-Icon.svg';
import callIconWhite from '../assets/images/packages/Melon-Mobile-Call-light-Icon.svg';
import smsIconWhite from '../assets/images/packages/Melon-Mobile-Text-light-Icon.svg';
import HeadingHardcoded from './HeadingHardcoded';
import Button from './Button';

function AllPackages() {

    // let packagesData: any = [
    //     {
    //         id: 1,
    //         title: 'Try Before You Buy',
    //         data: '1',
    //         call: '100',
    //         text: '100',
    //         price: 'FREE',
    //         icon: icon1,
    //         link: '/deals'
    //     },
    //     {
    //         id: 2,
    //         title: 'Student Melon',
    //         data: '20',
    //         call: '20',
    //         text: '150',
    //         price: 'R250',
    //         icon: icon2,
    //         link: '/packages/student'
    //     },
    //     {
    //         id: 3,
    //         title: 'Family Melon',
    //         data: '50',
    //         call: '200',
    //         text: '200',
    //         price: 'R450',
    //         icon: icon3,
    //         link: '/packages/family-melon'
    //     },
    //     {
    //         id: 4,
    //         title: 'Influencer Melon',
    //         data: '40',
    //         call: '50',
    //         text: '50',
    //         price: 'R400',
    //         icon: icon4,
    //         link: '/packages/influencer-melon'
    //     },
    //     {
    //         id: 5,
    //         title: 'Gamer Melon',
    //         data: '80',
    //         call: '50',
    //         text: '150',
    //         price: 'R250',
    //         icon: icon5,
    //         link: '/packages/gamer-melon'
    //     },
    //     {
    //         id: 6,
    //         title: 'Business Melon',
    //         data: '70',
    //         call: '400',
    //         text: '200',
    //         price: 'R450',
    //         icon: icon6,
    //         link: '/packages/business-melon'
    //     },
    //     {
    //         id: 7,
    //         title: 'Traveller Melon',
    //         data: '60',
    //         call: '200',
    //         text: '50',
    //         price: 'R400',
    //         icon: icon7,
    //         link: '/packages/traveller-melon'
    //     }
    // ];

    let packagesData: any = [];
    const [inView, setInView] = useState(false);
    const headingRef = React.useRef<HTMLImageElement>(null);
    const [ plannedPackagesData, setPlannedPackagesData ]: any = useState([]);
    const lottieRef = React.useRef<HTMLImageElement>(null);
    const [ref, inView2] = useInView({
        triggerOnce: true,
        rootMargin: '200px'
    });

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (lottieRef.current) {
          observer.observe(lottieRef.current);
        }
        return () => {
          observer.disconnect();
        };
    }, [lottieRef]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setInView(true);
            }
          });
        });
        if (headingRef.current) {
          observer.observe(headingRef.current);
        }
        return () => {
          observer.disconnect();
        };
    }, [headingRef]);

    // Fetching the packages once.
    useEffect( () => {
        
        // Fetching the planned packages.
        fetchPlanPackages();


    }, []);

     //Student plan animation
     const studentPlanOptions = {
        loop: true,
        autoplay: true, 
        animationData: studenPlan,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };
    //Family plan animation
    const familyPlanOptions = {
        loop: true,
        autoplay: true, 
        animationData: familyPlan,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };
    //Influencer plan animation
    const influencerPlanOptions = {
        loop: true,
        autoplay: true, 
        animationData: influencerPlan,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };
    //gamer plan animation
    const gamerPlanOptions = {
        loop: true,
        autoplay: true, 
        animationData: gamerPlan,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };
    //traveller plan animation
    const travellerPlanOptions = {
        loop: true,
        autoplay: true, 
        animationData: travellerPlan,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };
    //Business plan animation
    const businessPlanOptions = {
        loop: true,
        autoplay: true, 
        animationData: businessPlan,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    // Function to fetch data
    const fetchPlanPackages = async () => {
        fetchPackages('productOffering?fields=*&channel.id=a3cd8ce4-9259-4b14-8b17-b3e658ef0dbd&isSellable=true&offerType=Plan&expand=productOfferingPrice')
            .then( async ( packages: any ) => {
                
                // console.log( packages );

                if( packages.length > 0 ){
                    await createPlannedPackagesDataArray( packages )
                    .then( (  packagesCompiled: any ) => {
                        // console.log( packagesCompiled );

                        // Sorting by price.
                        sort_order_by_price(packagesCompiled);

                        setPlannedPackagesData(packagesCompiled);
                    })
                    .catch( ( error: any ) => {
                        console.log( error );
                    });
                }

            })
            .catch( (error: any) => {
                console.error( error );
            });
    }

    // Function to create the packages.
    const createPlannedPackagesDataArray = async ( packagesData: any ) => {

        let packagesDataArray: any = [];

        return new Promise( async ( resolve ) => {
            if ( packagesData ) {


                let compiledPackages = await Promise.all(
                    
                    packagesData.map( async (singlePackage: any, singlePackageIndex: any ) => {
                        // console.log( singlePackage );
                        let imageIcon: any = '';
                        let singlePackageObj: any = {};
                        let packagesValues: any = await createPlannedPackageValues(singlePackage.prodSpecCharValueUse);
                        let packagePrice: any = singlePackage.productOfferingPrice ? createPackagePrice(singlePackage.productOfferingPrice) : 0;
                        let imageUrl: any = singlePackage.attachment && singlePackage.attachment[0].url ? await fetchPackageImage( singlePackage.attachment[0].url ) : '';
                        let packageNameLink = convertTextToLinkFriendly(singlePackage.name);
                        let imageFetchLocalUrl = await getLocalImageByName(packageNameLink);

                        // console.log('Fetched image', singlePackage);


                        if( singlePackage.name !== 'Build Your Own Plan' ){
                            singlePackageObj['title'] = singlePackage.name === 'Build Your Own Plan' || singlePackage.name === 'Try Before You Buy' ? singlePackage.name : singlePackage.name + " Melon";
                            singlePackageObj['id'] = singlePackage.id;
                            singlePackageObj['data'] = packagesValues ? packagesValues.data : 0;
                            singlePackageObj['call'] = packagesValues ? convertMinutes(packagesValues.call) : 0;
                            singlePackageObj['text'] = packagesValues ? packagesValues.text.replace('/SMS/g', 'Texts') : 0;
                            singlePackageObj['icon'] = imageFetchLocalUrl ? imageFetchLocalUrl : '';
                            singlePackageObj['price'] = singlePackage.name === 'Try Before You Buy' ? "FREE" : "R "+ packagePrice;
                            singlePackageObj['link'] = singlePackage.name === 'Try Before You Buy' ? '/deals' : '/packages/'+packageNameLink+'-melon';
                        }

                        if( Object.keys(singlePackageObj).length > 0 ){

                            if ( singlePackage.name === 'Try Before You Buy' && packagesDataArray.length > 0 ) {
                                packagesDataArray.unshift( singlePackageObj );
                            } else {
                                packagesDataArray.push( singlePackageObj );
                            }
                            
                        }
        
                    })
                );
                

                return resolve(packagesDataArray);
            }
        });
    }

    const fetchPackageImage = async ( imageId: any ) => {
        
        return await new Promise( async ( imageResolve, imageReject ) => {
            try {
                await fetchInformationByString(imageId)
                    .then( imageUrl => imageResolve( imageUrl ) )
                    .catch( error => imageReject( error) );
            } catch (error) {
                console.error( error );
            }
        });

    }

    // Updating the image
    const getLocalImageByName = async ( packageName: string ) => {
        switch (packageName) {
            case 'gamer':
                return icon5;
            case 'business':
                return icon6;
            case 'traveller':
                return icon7;
            case 'try-before-you-buy':
                return icon1;
            case 'influencer':
                return icon4;
            case 'student':
                return icon2;
            default:
                return null;
        }
    }

    
  return (
    <div className='packages-list all-packages'>
        <div className="container">
            <div className="row justify-content-center">
                { plannedPackagesData && plannedPackagesData.length > 0 ? (
                    ( plannedPackagesData.map( (item:any) => (
                        <div key={item.id} className="col-12 col-md-6 col-lg-3 tile">
                            <div className={item.price == 'FREE' ? 'deal free' : 'deal'}>
                                <div className="header">
                                    <HeadingHardcoded title={item.title} />
                                </div>
                                <div className="body">
                                    {/* <div className="icon"><img src={item.icon} alt={item.title} /></div> */}
                                    <div className="icon">
                                        <div ref={lottieRef}>
                                            {
                                                item.title == "Try Before You Buy" &&
                                                <img src={item.icon} alt={item.title} loading='lazy' className='try-before-you-buy-icon' />
                                            }
                                            {
                                                item.title == "Student Melon" &&
                                                <>
                                                    <div className='d-block lottie-image' ref={ref}>
                                                        <Lottie options={studentPlanOptions} height={150} width={150} isStopped={false} />
                                                    </div>
                                                </>
                                            }
                                            {
                                                item.title == "Family Melon" &&
                                                <>
                                                    <div className='d-block lottie-image' ref={ref}>
                                                        <Lottie options={familyPlanOptions} height={150} width={150} isStopped={false} />
                                                    </div>
                                                </>
                                            }
                                            {
                                                item.title == "Influencer Melon" &&
                                                <>
                                                    <div className='d-block lottie-image' ref={ref}>
                                                        <Lottie options={influencerPlanOptions} height={150} width={150} isStopped={false} />
                                                    </div>
                                                </>
                                            }
                                            {
                                                item.title == "Gamer Melon" &&
                                                <>
                                                    <div className='d-block lottie-image' ref={ref}>
                                                        <Lottie options={gamerPlanOptions} height={150} width={150} isStopped={false} />
                                                    </div>
                                                </>
                                            }
                                            {
                                                item.title == "Traveller Melon" &&
                                                <>
                                                    <div className='d-block lottie-image' ref={ref}>
                                                        <Lottie options={travellerPlanOptions} height={150} width={150} isStopped={false} />
                                                    </div>
                                                </>
                                            }
                                            {
                                                item.title == "Business Melon" &&
                                                <>
                                                    <div className='d-block lottie-image' ref={ref}>
                                                        <Lottie options={businessPlanOptions} height={150} width={150} isStopped={false} />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="details">
                                        <p>
                                            <img src={dataIcon} width='25' height='25' loading='lazy' alt="Data" /> 
                                            <strong> Data</strong> {item.data}
                                        </p>
                                        <p>
                                            <img src={callIcon} width='25' height='25' loading='lazy' alt="Call" /> 
                                            <strong> Call</strong> {item.call}
                                        </p>
                                        <p>
                                            <img src={smsIcon} width='25' height='25' loading='lazy' alt="Text" /> 
                                            <strong>Text</strong> {item.text}
                                        </p>
                                    </div>
            
                                    <div className="price">
                                        <p><strong>{item.price}</strong> {item.price == 'FREE' ? <span>for 1 month</span> : <span>per month</span>}</p>
                                    </div>
            
                                    <div className="link">
                                        <Button 
                                            type='button'
                                            className='btn btn-primary'
                                            link={ item.link }
                                            linkText= {item.price == 'FREE' ? 'Learn more' : 'See plan details'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )))
                ) : (
                    <InlineLoading paragraph='Loading standard packages...' title='Ready to view packages?'></InlineLoading>
                ) }
            </div>
        </div>
    </div>
  )
}

export default AllPackages