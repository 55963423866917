import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/Melon-Mobile-Logo.svg';
import Button from './Button';

function Navigation() {
    const [isShow, setIsShow] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [hover, setHover] = useState(false);
    const [hasClass, setHasClass] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [aboutMenu, setAboutMenu] = useState(false);
    const [planMenu, setPlanMenu] = useState(false);

    const handleMouseEnter = () => {
      setHover(true);
    };
      
    const handleMouseLeave = () => {
      setHover(false);
    };
    
    const handleHover = () => {
        if (hasClass) {
            setHasClass(false);
        } else {
            setHasClass(true);
            if (500) {
            setTimeout(() => {
                setHasClass(false);
            }, 500);
            }
        }
    };
    const navigate = useNavigate();

    const handleSearch = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const response = await fetch(
            `https://melonmobilecontent.agletinteractive.com/wp-json/wp/v2/pages?search=${searchQuery}`
        );
        const data = await response.json();
        localStorage.setItem("searchResults", JSON.stringify(data));
        localStorage.removeItem("searchQuery");
        localStorage.setItem("searchQuery", searchQuery);
        navigate("/search#");
    };

    useEffect(() => {
        const handleScroll = () => {
          if (window.pageYOffset > 100 && !scrolled) {
            setScrolled(true);
          } else if (window.pageYOffset <= 100 && scrolled) {
            setScrolled(false);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, [scrolled]);
  return (
    <div className={`nav-wrapper ${scrolled ? 'scrolled' : ''}`}>
        <div className={showSearch ? 'search-bar show' : 'search-bar'}>
            <div className="container">
                <div className="input">
                    <form onSubmit={handleSearch}>
                        <input type="text" placeholder='Enter your search here...' onChange={(e) => setSearchQuery(e.target.value)} />
                        <button className="btn btn-primary" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ position: 'absolute' }}>
                            <span className='text'>Search</span>
                            <span 
                                className='bg'
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: hover ? '300%' : 0,
                                    height: hover ? '300%' : 0,
                                    transition: 'all 0.2s ease-out',
                                }}
                            ></span>
                        </button>
                    </form>
                </div>
                <button className={`close ${hasClass ? '' : ""}`} data-animation='xMarks' data-remove={500} onMouseEnter={handleHover} onMouseLeave={() => setHasClass(false)} onClick={() => setShowSearch(false)}></button>
            </div>
        </div>
        <div className="info-bar text-center">
            <small>Try Before You Buy with 1GB & 100 minutes for free (no catches)! <NavLink to="/deals">Find out more</NavLink></small>
        </div>
        <header className="navigation">
            <div className='container'>
                <nav className='navbar navbar-expand-lg'>
                    <NavLink className='navbar-brand' to={'/'}><img src={logo} alt='Melon Mobile' width='162' height='49.3'/></NavLink>
                    <button className={isShow ? 'navbar-toggler open' : 'navbar-toggler'} onClick={() => setIsShow(!isShow)}>
                        <span></span>
                    </button>
                    <div className={isShow ? 'collapse navbar-collapse justify-content-end show' : 'collapse navbar-collapse justify-content-end'}>
                        <ul className='navbar-nav'>
                            <li className='nav-item dropdown'>
                                <NavLink className='nav-link dropdown-toggle' to={'/who-we-are#'} onClick={() => setIsShow(false)}>About </NavLink> <i className={aboutMenu ? 'open arrow' : 'arrow'} onClick={() => setAboutMenu(!aboutMenu)} ></i>
                                <div className={aboutMenu ? 'dropdown-menu open' : 'dropdown-menu'}>
                                    <NavLink className='dropdown-item' to={'/who-we-are#'} onClick={() => setIsShow(false)}>Who we are</NavLink>
                                    <NavLink className='dropdown-item' to={'/why-use-melon-mobile#'} onClick={() => setIsShow(false)}>Why Melon</NavLink>
                                    <NavLink className='dropdown-item' to={'/how-it-works#'} onClick={() => setIsShow(false)}>How it works</NavLink>
                                </div>
                            </li>
                            <li className='nav-item dropdown'>
                                <NavLink className='nav-link dropdown-toggle' to={'/packages#'} onClick={() => setIsShow(false)}>Melon Plans</NavLink>
                                <i className={planMenu ? 'open arrow' : 'arrow'} onClick={() => setPlanMenu(!planMenu)} ></i>
                                <div className={planMenu ? 'dropdown-menu open' : 'dropdown-menu'}>
                                    <NavLink className='dropdown-item' to={'/packages/student-melon#'} onClick={() => setIsShow(false)}>Student Melon</NavLink>
                                    {/* <NavLink className='dropdown-item' to={'/packages/family-melon#'} onClick={() => setIsShow(false)}>Family Melon</NavLink> */}
                                    <NavLink className='dropdown-item' to={'/packages/influencer-melon#'} onClick={() => setIsShow(false)}>Influencer Melon</NavLink>
                                    <NavLink className='dropdown-item' to={'/packages/gamer-melon#'} onClick={() => setIsShow(false)}>Gamer Melon</NavLink>
                                    <NavLink className='dropdown-item' to={'/packages/business-melon#'} onClick={() => setIsShow(false)}>Business Melon</NavLink>
                                    <NavLink className='dropdown-item' to={'/packages/traveller-melon#'} onClick={() => setIsShow(false)}>Traveller Melon</NavLink>
                                </div>
                            </li>
                            {/* Rewards */}
                            {/* <li className='nav-item'>
                                <NavLink className='nav-link' to={'/rewards#'} onClick={() => setIsShow(false)}>Rewards</NavLink>
                            </li> */}
                            <li className='nav-item'>
                                <NavLink className='nav-link' to={'/deals#'} onClick={() => setIsShow(false)}>Deals</NavLink>
                            </li>
                            <li className='nav-item'>
                                <NavLink className='nav-link' to={'/contact#'} onClick={() => setIsShow(false)}>Contact</NavLink>
                            </li>
                            <li className='nav-item' onClick={() => setIsShow(false)}>
                                <NavLink className='nav-link' to={'/self-help'} onClick={() => setIsShow(false)}>Self-Help</NavLink>
                            </li>
                            <li className='nav-item ai-nav-download-btn' onClick={() => setIsShow(false)}>
                                <Button type='button' className='btn btn-primary' linkText='Download the app' link={'/download-app#'} />
                            </li>

                            {/* Login */}
                            {/* <li className='nav-item ai-nav-login' onClick={() => setIsShow(false)}>
                                <Button type='button' className='login' linkText='Login' link={'#'} />
                            </li> */}
                            
                            <li className='nav-item'>
                                <button className='search-icon' onClick={() => setShowSearch(true)}><i className='icon icon-search'></i></button> 
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    </div>
  )
}

export default Navigation