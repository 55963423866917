import React, { useEffect, useState } from 'react';

interface loadingInlineContent {
    title?: string,
    paragraph?: string
}

function HeadingTwo( { title, paragraph }:loadingInlineContent  ) {
    return (
        <div className="ai-page-loader-wrapper">
            <span className="ai-inline-loader"></span>
            { (title && title.length > 0 || paragraph && paragraph.length > 0) &&
               <div className='ai-inline-loader-content-wrapper'>
                { title && title.length > 0 && (
                    <h5>{title}</h5>
                )}
                { paragraph && paragraph.length > 0 && (
                    <p>{paragraph}</p>
                )}
               </div>
            }
        </div>
      )
}

export default HeadingTwo;