import React, { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom'
import Cookies from './components/Cookies';
import {router} from "./router";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import PageLoader from "./components/PageLoader";

function App() {
    return (
      <Suspense fallback={<div className={'ai-suspense-fallback'}></div>}>
        <div className="App">
          <HelmetProvider>
            <RouterProvider router={router} fallbackElement={<PageLoader/>}/>
          </HelmetProvider>
        </div>
      </Suspense>
    );
  }

export default App;