import React from 'react';
import { Link, NavLink } from 'react-router-dom';

//Icons
import logo from '../assets/images/Melon-Mobile-Logo.svg';
import { RiLinkedinBoxFill } from 'react-icons/ri';
import { RiTwitterFill } from 'react-icons/ri';
import { RiInstagramFill } from 'react-icons/ri';
import { FaFacebook } from 'react-icons/fa';
import { FaTiktok } from 'react-icons/fa';

function Footer() {
  return (
    <footer id="footer" className='ai-py-4'>
        <div className="container">
            <div className="d-flex">
                <div className="ai-brand">
                    <NavLink className='navbar-brand' to={'/'}><img src={logo} alt='Melon Mobile' loading='lazy' width='162' height='49.3'/></NavLink>
                    <p className='d-none d-lg-block'><small>&copy;Copyright Melon Mobile. <br /> All rights reserved. Website by <a href="https://aglet.co.za/" target='_blank' rel='noreferrer'>Aglet.</a></small></p>
                </div>
                <div className="ai-links">
                    <h4>Help Centre</h4>
                    <ul className="list-unstyled">
                        {/* <li className='nav-item'>
                            <NavLink className='nav-link' to={'/blog'}>Blog</NavLink>
                        </li> */}
                        <li className='nav-item'>
                            <NavLink className='nav-link' to={'/help-center#ask'}>Ask the Melon</NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink className='nav-link' to={'/help-center#faqs'}>General FAQs</NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink className='nav-link' to={'/help-center#account'}>Account Questions </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink className='nav-link' to={'/help-center#how-it-works'}>How It Works</NavLink>
                        </li>
                    </ul>
                </div>
                <div className="ai-links">
                    <h4>Mobile</h4>
                    <ul className='list-unstyled'>
                        <li className='nav-item'>
                            <NavLink className='nav-link' to={'/packages'}>Melon Plans</NavLink>
                        </li>
                        {/* Rewards */}
                        {/* <li className='nav-item'>
                            <NavLink className='nav-link' to={'/rewards'}>Rewards</NavLink>
                        </li> */}
                        <li className='nav-item'>
                            <NavLink className='nav-link' to={'/deals'}>Deals</NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink className='nav-link' to={'/get-a-sim-promo'}>Get a free sim</NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink className='nav-link' to={'/help-center'}>FAQ</NavLink>
                        </li>
                    </ul>
                </div>
                <div className="ai-links">
                    <h4>Legal</h4>
                    <ul className='list-unstyled'>
                        <li className='nav-item'>
                            <NavLink className='nav-link' to={'/legal'}>Privacy Policy</NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink className='nav-link' to={'/terms-of-use'}>Terms of use</NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink className='nav-link' to={'/terms-and-conditions'}>Terms & Conditions</NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink className='nav-link' to={'/data-deletion-policy'}>Data Deletion</NavLink>
                        </li>
                    </ul>
                </div>
                <div className="ai-links">
                    <h4>Get in touch</h4>
                    <div className="icons">
                        <a href="https://www.facebook.com/melonmobileza" target="_blank" rel='noreferrer'><FaFacebook /></a>
                        <a href="https://twitter.com/melonmobileza" target="_blank" rel='noreferrer'><RiTwitterFill /></a>
                        <a href="https://www.linkedin.com/company/melon-mobile/" target="_blank" rel='noreferrer'><RiLinkedinBoxFill /></a>
                        <a href="https://www.tiktok.com/@melonmobile" target="_blank" rel='noreferrer'><FaTiktok /></a>
                        <a href="https://www.instagram.com/melonmobile_za/" target="_blank" rel='noreferrer'><RiInstagramFill /></a>
                    </div>
                    <div className="copyright-mobile d-lg-none">
                        <p><small>&copy;Copyright Melon Mobile. <br /> All rights reserved. Website by <a href="https://aglet.co.za/" target='_blank' rel='noreferrer'>Aglet.</a></small></p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer