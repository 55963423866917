import React, { useState, useEffect } from 'react';
import {useLoaderData} from "react-router-dom";
import { PageData } from "../types/PageData";
import Accordion from '../components/Accordion';
import Banner from '../components/Banner';

import background from '../assets/images/Melon-Mobile-Banner-Background.jpg';
import SupportCta from '../components/SupportCta';
import { Helmet } from 'react-helmet-async';
import HeadingTwo from '../components/HeadingTwo';
import Navigation from '../components/Navigation';

const HelpCenter = () => {
    const [selectedChild, setSelectedChild] = useState<string | null>(null);

    useEffect(() => {
        document.body.style.scrollBehavior = "auto";
        document.body.scrollTop = 0;
        document.documentElement.style.scrollBehavior = "auto";
        document.documentElement.scrollTop = 0;
    }, []);

    const handleClick = (childId: string) => {
      setSelectedChild(childId);
    };

    const id = window.location.hash.substring(1);

    useEffect(() => {
        if (id) {
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({ behavior: "auto" });
          }
        }
      }, []);
    // Page Data From Router
    const PageData = useLoaderData() as PageData;
    const items = PageData.content.no_short_codes
    const yoast:any = PageData.yoast_head_json

  return (
    <div className='help-center'>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : "Help center - Melon Mobile"}</title>
            <meta charSet="utf-8" />
            <meta name="description" content={yoast !== undefined ? yoast.description : "We believe the current way you’re forced to mobile is broken. With big networks offering things that really only benefit themselves. Long-term contracts, outdated plans, complicated experiences, and a one-size-fits-all approach have left most of us feeling rather bitter."}/>
            <meta property="og:description" content={yoast !== undefined ? yoast.og_description: "We believe the current way you’re forced to mobile is broken. With big networks offering things that really only benefit themselves. Long-term contracts, outdated plans, complicated experiences, and a one-size-fits-all approach have left most of us feeling rather bitter."} />
            <meta property="og:locale" content={yoast !== undefined ? yoast.og_locale : "en_US"} />
            <meta property="og:type" content={yoast !== undefined ? yoast.og_type : 'article'} />
            <meta property="og:title" content={yoast !== undefined ? yoast.og_title : "Help center - Melon Mobile"} />
            <meta property="og:site_name" content={yoast !== undefined ? yoast.og_site_name : "Melon Mobile"} />
            <meta property="og:url" content={yoast !== undefined ? yoast.og_url : `${process.env.REACT_APP_SECRET_MELON_MOBILE_CONTENT}help-center/`} />
        </Helmet>
        <Navigation />
        <Banner 
            badge={PageData.title.rendered} 
            title={items[0][0][0]}
            background={background} 
        />

        <div id="ask" className="container accordion-wrapper">
            {items[1][0][0] === '' ? null : <HeadingTwo title={items[1][0][0]} />}

            <Accordion 
                id="1" onClick={handleClick} isSelected={selectedChild === '1'}
                question={items[1][1][0]}
                answer={items[1][1][1] + items[1][1][2] + items[1][1][3]}
            />
            <Accordion
                id="2" onClick={handleClick} isSelected={selectedChild === '2'}
                question={items[1][1][4]}
                answer={items[1][1][5] + items[1][1][6] + items[1][1][7]}
            />
            <Accordion
                id="3" onClick={handleClick} isSelected={selectedChild === '3'}
                question={items[1][1][8]}
                answer={items[1][1][9]}
            />
            <Accordion
                id="4" onClick={handleClick} isSelected={selectedChild === '4'}
                question={items[1][1][10]}
                answer={items[1][1][11]}
            />
            {/* <Accordion
                id="5" onClick={handleClick} isSelected={selectedChild === '5'}
                question={items[1][1][12]}
                answer={items[1][1][13] + items[1][1][14] + items[1][1][15] + items[1][1][16] + items[1][1][17] + items[1][1][18] + items[1][1][19]}
            /> */}
            <Accordion
                id="6" onClick={handleClick} isSelected={selectedChild === '6'}
                question={items[1][1][20]}
                answer={items[1][1][21] + items[1][1][22]}
            />
            <Accordion
                id="7" onClick={handleClick} isSelected={selectedChild === '7'}
                question={items[1][2][0]}
                answer={items[1][2][1]}
            />
          <Accordion
              id="8" onClick={handleClick} isSelected={selectedChild === '8'}
              question={items[1][2][2]}
              answer={items[1][2][3]}
          />
          <Accordion
              id="9" onClick={handleClick} isSelected={selectedChild === '9'}
              question={items[1][2][4]}
              answer={items[1][2][5]}
          />
          <Accordion
              id="10" onClick={handleClick} isSelected={selectedChild === '10'}
              question={items[1][2][6]}
              answer={items[1][2][7]}
          />
          <Accordion
              id="11" onClick={handleClick} isSelected={selectedChild === '11'}
              question={items[1][2][8]}
              answer={items[1][2][9]}
          />
        </div>

        <div id="faqs" className="container accordion-wrapper">
            {items[2][0][0] === '' ? null : <HeadingTwo title={items[2][0][0]} />}

            <Accordion 
                id="12" onClick={handleClick} isSelected={selectedChild === '12'}
                question={items[2][1][0]}
                answer={items[2][1][1]}
            />
            <Accordion 
                id="13" onClick={handleClick} isSelected={selectedChild === '13'}
                question={items[2][1][2]}
                answer={items[2][1][3]}
            />
            <Accordion
                id="14" onClick={handleClick} isSelected={selectedChild === '14'}
                question={items[2][1][4]}
                answer={items[2][1][5] + items[2][1][6] + items[2][1][7]}
            />
            <Accordion
                id="16" onClick={handleClick} isSelected={selectedChild === '16'} 
                question={items[2][1][8]}
                answer={items[2][1][9]}
            />
            <Accordion
                id="17" onClick={handleClick} isSelected={selectedChild === '17'} 
                question={items[2][1][10]}
                answer={items[2][1][11]}
            />
          <Accordion
              id="18" onClick={handleClick} isSelected={selectedChild === '18'}
              question={items[2][1][12]}
              answer={items[2][1][13] + items[2][1][14] + items[2][1][15] + items[2][1][16] + items[2][1][17] + items[2][1][18]}
          />
        </div>
        
        <div id="account" className="container accordion-wrapper">
            {items[3][0][0] === '' ? null : <HeadingTwo title={items[3][0][0]} />}

            <Accordion
                id="19" onClick={handleClick} isSelected={selectedChild === '19'}
                question={items[3][1][0]}
                answer={items[3][1][1]}
            />
            <Accordion
                id="20" onClick={handleClick} isSelected={selectedChild === '20'}
                question={items[3][1][2]}
                answer={items[3][1][3]}
            />
            <Accordion
                id="21" onClick={handleClick} isSelected={selectedChild === '21'}
                question={items[3][1][4]}
                answer={items[3][1][5]}
            />
            <Accordion
                id="22" onClick={handleClick} isSelected={selectedChild === '22'}
                question={items[3][1][6]}
                answer={items[3][1][7]}
            />
            <Accordion 
                id="23" onClick={handleClick} isSelected={selectedChild === '23'}
                question={items[3][1][8]}
                answer={items[3][1][9]}
            />
            <Accordion
                id="24" onClick={handleClick} isSelected={selectedChild === '24'}
                question={items[3][1][10]}
                answer={items[3][1][11]}
            />
            <Accordion
                id="25" onClick={handleClick} isSelected={selectedChild === '25'}
                question={items[3][1][12]}
                answer={items[3][1][13]}
            />
            <Accordion
                id="26" onClick={handleClick} isSelected={selectedChild === '26'}
                question={items[3][1][14]}
                answer={items[3][1][15]}
            />
            <Accordion
                id="27" onClick={handleClick} isSelected={selectedChild === '27'}
                question={items[3][1][16]}
                answer={items[3][1][17]}
            />
            <Accordion
                id="28" onClick={handleClick} isSelected={selectedChild === '28'}
                question={items[3][1][18]}
                answer={items[3][1][19]}
            />
        </div>

        <div id="how-it-works" className="container accordion-wrapper">
            {items[4][0][0] === '' ? null : <HeadingTwo title={items[4][0][0]} />}

            <Accordion
                id="29" onClick={handleClick} isSelected={selectedChild === '29'}
                question={items[4][1][0]}
                answer={items[4][1][1]}
            />
            <Accordion
                id="30" onClick={handleClick} isSelected={selectedChild === '30'}
                question={items[4][1][2]}
                answer={items[4][1][3]}
            />
            <Accordion
                id="31" onClick={handleClick} isSelected={selectedChild === '31'}
                question={items[4][1][4]}
                answer={items[4][1][5]}
            />
            <Accordion
                id="32" onClick={handleClick} isSelected={selectedChild === '32'}
                question={items[4][1][6]}
                answer={items[4][1][7]}
            />
            <Accordion
                id="33" onClick={handleClick} isSelected={selectedChild === '33'}
                question={items[4][1][8]}
                answer={items[4][1][9]}
            />

            <Accordion
                id="34" onClick={handleClick} isSelected={selectedChild === '34'}
                question={items[4][1][10]}
                answer={items[4][1][11]}
            />
        </div>

        <div id="packages" className="container accordion-wrapper">
            {items[5][0][0] === '' ? null : <HeadingTwo title={items[5][0][0]} />}

            <Accordion 
                id="35" onClick={handleClick} isSelected={selectedChild === '35'}
                question={items[5][1][0]}
                answer={items[5][1][1]}
            />
            <Accordion 
                id="36" onClick={handleClick} isSelected={selectedChild === '36'}
                question={items[5][1][2]}
                answer={items[5][1][3]}
            />
            <Accordion 
                id="37" onClick={handleClick} isSelected={selectedChild === '37'}
                question={items[5][1][4]}
                answer={items[5][1][5]}
            />
            <Accordion 
                id="38" onClick={handleClick} isSelected={selectedChild === '38'}
                question={items[5][1][6]}
                answer={items[5][1][7]}
            />
            <Accordion 
                id="39" onClick={handleClick} isSelected={selectedChild === '39'}
                question={items[5][1][8]}
                answer={items[5][1][9]}
            />
        </div>

        <div id="deals" className="container accordion-wrapper">
            {items[6][0][0] === '' ? null : <HeadingTwo title={items[6][0][0]} />}

            <Accordion 
                id="40" onClick={handleClick} isSelected={selectedChild === '40'}
                question={items[6][1][0]}
                answer={items[6][1][1]}
            />
            <Accordion
                id="41" onClick={handleClick} isSelected={selectedChild === '41'}
                question={items[6][1][2]}
                answer={items[6][1][3]}
            />
            <Accordion
                id="42" onClick={handleClick} isSelected={selectedChild === '42'}
                question={items[6][1][4]}
                answer={items[6][1][5]}
            />
            <Accordion
                id="43" onClick={handleClick} isSelected={selectedChild === '43'}
                question={items[6][1][6]}
                answer={items[6][1][7]}
            />
        </div>

        <div id="sim" className="container accordion-wrapper">
            {items[7][0][0] === '' ? null : <HeadingTwo title={items[7][0][0]} />}

            <Accordion 
                id="44" onClick={handleClick} isSelected={selectedChild === '44'}
                question={items[7][1][0]}
                answer={items[7][1][1]}
            />
            <Accordion 
                id="45" onClick={handleClick} isSelected={selectedChild === '45'}
                question={items[7][1][2]}
                answer={items[7][1][3]}
            />
            <Accordion
                id="46" onClick={handleClick} isSelected={selectedChild === '46'}
                question={items[7][1][4]}
                answer={items[7][1][5]}
            />
            <Accordion
                id="47" onClick={handleClick} isSelected={selectedChild === '47'}
                question={items[7][1][6]}
                answer={items[7][1][7]}
            />
        </div>

        <SupportCta />
    </div>
  );
};

export default HelpCenter;