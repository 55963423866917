import React from 'react';
import appStore from '../assets/images/Melon-Mobile-Download-App-Store.svg';
import googlePlay from '../assets/images/Melon-Mobile-Download-Google-Play.svg';
import HeadingHardcoded from './HeadingHardcoded';

interface ctaProps {
    image: string,
    title: string,
    copy: string
}
function DownloadCtaRed({image, title, copy}: ctaProps) {
  return (
    <div className='container'>
        <div className="download-cta red">
            <div className="row">
                <div className="col-12 col-lg-6 text">
                    <HeadingHardcoded title={title} />
                    <div dangerouslySetInnerHTML={{ __html: copy }} />
                    <div className="stores">
                        <a className="mb-3 d-inline-block" href="https://apps.apple.com/bg/app/melon-mobile/id1659235762" target={'_blank'} rel="noreferrer">
                            <img src={appStore} alt="App Store" loading='lazy'/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.shinemobileapplication.melon" target={'_blank'} rel="noreferrer">
                            <img src={googlePlay} alt="App Store" loading='lazy' />
                        </a>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    {/* <img className='main-image' src={image} alt="Download CTA" loading='lazy'/> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default DownloadCtaRed